import VideoPlayerYoutube from 'react-player/youtube';

function ReactVideoPlayerYoutube() {
  return (
    <VideoPlayerYoutube
      playing={false}
      width="100%"
      height="100%"
      controls={true}
      url={'https://youtu.be/jyGw9Gx929c?si=uAGOlEqrIXMMGsky'}
    />
  );
}
function ReactVideoPlayerYoutube2() {
  return (
    <VideoPlayerYoutube
      playing={false}
      width="100%"
      height="100%"
      controls={true}
      url={'https://youtu.be/v7zLdhQwmsw?si=dePxLCR3MvpllEv2'}
    />
  );
}
function ReactVideoPlayerYoutube3() {
  return (
    <VideoPlayerYoutube
      playing={false}
      width="100%"
      height="100%"
      controls={true}
      url={'https://youtu.be/ymmEAsj-gmQ?si=ei9hMF4gjIt1AXqp'}
    />
  );
}

export {
  ReactVideoPlayerYoutube,
  ReactVideoPlayerYoutube2,
  ReactVideoPlayerYoutube3,
};
