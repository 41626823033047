import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import quilon from '../../img/HopClean/articel.jpg';
import quilon2 from '../../img/HopClean/galler9.jpg';
import '../Articels/Articeles.css';
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>En</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/articelone">Pl</Link>
          ) : (
            <Link to="/en/articelone">En</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

export function Cataloge() {
  const [isScroll, setScroll] = useState(true);

  useEffect(() => {
    if (isScroll) {
      window.scroll({ top: -100, behavior: 'smooth' });
      setScroll(false);
    }
  }, [isScroll]);

  return (
    <div className="catalogs">
      <h3>Katalog</h3>
      <ul>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articelone">Furniture upholstery cleaning</NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articeltwo">Laundry of mattresses</NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articelthree">
            How often should you clean upholstered furniture in the office
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articelfour">
            Recommended mattress cleaning frequency
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articelfive">Washing carpets and rugs </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articelsix">
            Cleaning mattresses in kindergartens{' '}
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articelseven">
            Laundry of furniture on the plane{' '}
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articel8">
            {' '}
            How often should the carpet in the office be cleaned
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articel9">
            Washing curtains, curtains, blinds
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articel10">
            Laundry and cleaning of upholstered furniture on yachts and boats
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articel11">Washing car upholstery</NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articel12">Laundry of prams and car seats</NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articel13">
            How to clean upholstered sofas and chairs?
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articel14">Cleaning leather upholstery</NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articel15">
            How to clean leather furniture yourself
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articel16">
            How to clean a carpet yourself at home
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articel17">
            How to clean upholstered furniture at home?
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articel18">
            How to clean a mattress yourself at home
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articel19">
            How to clean plush toys yourself at home
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articel20">
            How to clean curtains at home yourself
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articel21">
            How to clean your car ceiling yourself at home
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articel22">
            How to clean pet hair yourself at home
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/en/articel23">
            How to clean a baby stroller at home?
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Furniture upholstery cleaning</h2>

            <p>
              Washing upholstered furniture is one of the most important
              activities related to maintaining hygiene in our homes or
              workplaces. Upholstered furniture is not only a place to relax,
              but also often serves as a surface to work or eat. Therefore, they
              collect a lot of dirt, bacteria and mites that can negatively
              affect our health.
            </p>
            <p>
              One of the most important reasons why it is worth washing
              upholstered furniture regularly is to remove dirt from it.
              Upholstered furniture, especially those in bright colors, quickly
              starts to look dirty and worn. Dirt, such as food stains, oil
              stains or dried food remains, are very difficult to remove from
              the surface of the upholstery. Regular washing can help remove
              these stains and restore your furniture to its original
              appearance.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              The second important reason for washing upholstered furniture is
              to prevent the growth of bacteria and fungi. In the case of dirt
              and moisture, fungi and bacteria can develop on the surface of
              upholstered furniture, which can cause diseases and allergies.
              Washing upholstered furniture can help remove these
              microorganisms, which in turn will benefit our health.
            </p>
            <p>
              The third reason why it is worth washing upholstered furniture is
              to remove mites. Dust mites are small organisms that live in dust
              and debris on furniture surfaces. They are the cause of allergies
              in many people, especially those who have problems with the
              respiratory system. Regular washing of upholstered furniture can
              help remove dust mites, which in turn will improve the air quality
              in our homes.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              In conclusion, washing upholstered furniture is important for
              maintaining hygiene in our homes and workplaces. It helps in
              removing dirt, bacteria and mites that can negatively affect our
              health. Regular washing of upholstered furniture can help maintain
              its appearance and quality for longer.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function ArticelOne() {
  return (
    <div className="body_wrapper">
      <FirstScreenEn />
      <Lang />
      <Quilon />
      <FiveSCreenEn />
    </div>
  );
}
export default ArticelOne;
