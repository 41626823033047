import React from 'react';
// import Choose from './Choose';
import './ContainerElements.css';
import { Link } from 'react-router-dom';
import Service from './Service';

function ContainerH2H3() {
  return (
    <>
      <h3 className="h3">PROFESJONALNE PRANIE</h3>
      <h1 className="h1">
        KANAPY, FOTELE, MATERACE, WÓZKI, ZASŁONY, ROLETY, WYKŁADZINY, DYWANY,
        TAPICERKA SAMOCHODOWA...
      </h1>
      <h2 className="h2">
        👉 GWARANTUJEMY ZWROT 100% OPŁATY, JEŚLI KLIENT NIE BĘDZIE ZADOWOLONY Z
        EFEKTU PRANIA !!!
      </h2>

      {/* <h1>Pranie tapicerek meblowych i samochodowych, dywanów i wykładzin</h1> */}
    </>
  );
}
function Check() {
  return (
    <div className="check">
      <li>
        <Link to="/cennik">Szczegółowo...</Link>
      </li>
    </div>
  );
}
function ContainerElements() {
  return (
    <div className="container">
      <ContainerH2H3 />
      <Service />
      {/* <Choose /> */}
      <Check />
    </div>
  );
}

export default ContainerElements;
