import window from './../../../components/img/HopClean/updateArticles/foto8.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>En</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/articel17">Pl</Link>
          ) : (
            <Link to="/en/articel13">En</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>How to clean upholstered furniture at home?</h2>
            <p>
              Cleaning upholstered furniture may seem like a difficult task, but
              with the right tools and methods, it can be done effectively at
              home.
            </p>
            <img className="window" src={window} title="window" alt="window" />

            <p>
              Here's a step-by-step guide on how to care for your sofas,
              armchairs and other upholstered furniture.
            </p>
            <ul className="decical-ol">
              <li>
                Preparation for cleaning. Materials and tools:
                <ul>
                  <li>Vacuum cleaner with upholstery attachment</li>
                  <li>Brush with soft bristles </li>
                  <li>Microfiber cloths</li>
                  <li>Bowl of water</li>
                  <li>A gentle detergent or a special upholstery cleaner</li>
                  <li>Baking soda</li>
                  <li>Vinegar</li>
                  <li>Distilled water</li>
                </ul>
              </li>
              <li>
                Vacuuming. Start by thoroughly vacuuming the furniture. Use a
                vacuum cleaner with an upholstery attachment to remove dust, pet
                hair and other loose debris. Be sure to reach all the nooks and
                crannies.
              </li>
              <li>
                Stain removal. Fresh stains:
                <ul>
                  <li>
                    As soon as possible after they occur, use a microfiber cloth
                    to gently absorb the liquid. Do not rub so as not to spread
                    the stain.
                  </li>
                  <li>
                    You can use a solution of water and a little detergent. Soak
                    the cloth and gently wash the stain.
                  </li>
                </ul>
                Older stains:
                <ul>
                  <li>
                    For more difficult stains, such as red wine or coffee, you
                    can use a mixture of water and vinegar (1:1 ratio). Apply
                    the solution to the stain, then rub gently.
                  </li>
                  <li>
                    Baking soda also works well for removing odors and stains.
                    Sprinkle baking soda on the stain, leave it for a few hours,
                    and then vacuum it up.
                  </li>
                </ul>
              </li>
              <li>
                General cleaning. Prepare the cleaning solution:
                <ul>
                  <li>
                    In a bowl, mix warm water with a mild detergent or a special
                    upholstery cleaner.
                  </li>
                  <li>
                    Soak a microfiber cloth in the solution, wring it well so
                    that it is only slightly damp.
                  </li>
                  <li>
                    Wipe the entire surface of the furniture, paying particular
                    attention to the more dirty areas.
                  </li>
                </ul>
              </li>
              <li>
                Rinsing. After cleaning the upholstery with detergent, it is
                important to remove any cleaning agent residues. Use a clean
                cloth soaked in distilled water to clean the furniture.
              </li>
              <li>
                Drying. Make sure the furniture is well dried. Open windows or
                turn on a fan to speed up drying. Never leave furniture damp as
                this may lead to mold and unpleasant odors.
              </li>
              <li>
                Care and maintenance. To keep the upholstery looking new for a
                long time, it is worth vacuuming and cleaning it regularly.
                Avoid direct sunlight, which may cause the material to fade.
                Remember that some materials require specialized care, so it is
                always worth checking the furniture manufacturer's label. If in
                doubt, you can consult a professional.
              </li>
            </ul>
            <p>
              Thanks to these simple steps, your upholstered furniture will
              always be clean and well-kept, which will extend its life and
              improve the aesthetics of the interior.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreenEn />
      <Lang />
      <Furnitura />
      <FiveSCreenEn />
    </div>
  );
}
export default ArticelTwo;
