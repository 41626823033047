
import window from './../../img/HopClean/galler.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";
import React, {useState} from "react";
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';


function Lang () {
    const [isLang, setLang] = useState(false);
    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>En</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/articelfour'>Pl</Link> :<Link to='/en/articelfour'>En</Link>} </button>
        </div>
        </>
    )
}


function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Recommended mattress cleaning frequency</h2>
            <p>
            The mattress is one of the most important pieces of equipment in our bedrooms, but we often forget that it also requires regular cleaning. Cleaning your mattress at home is crucial as it removes pollutants, bacteria and mites that can lead to health issues such as asthma, allergies and skin problems. In this article, we will discuss how often we should clean the mattress at home and what methods can be used.
            </p>
            <p>
            How often you clean your mattress depends on your individual needs and home circumstances, but in general it is recommended that mattresses be cleaned at least every six months. Of course, there are circumstances when cleaning needs to be done in less time. For example, if someone is allergic, it is recommended to clean the mattress every 3-4 months to avoid the accumulation of allergens.
            </p>
            <img className="quilon" src={window} title='window' alt="window"/>
            <p>
            There are many ways to clean a mattress, including:
            </p>
            <ul className="windowraz">
                <li>Vacuuming is the primary method of removing dust and dirt from a mattress. However, it is worth investing in a vacuum cleaner with a HEPA filter, which allows you to remove allergens from the air. Vacuuming should be done regularly to avoid the accumulation of dust and dirt.</li>
                <li>
                Vacuuming is the primary method of removing dust and dirt from a mattress. However, it is worth investing in a vacuum cleaner with a HEPA filter, which allows you to remove allergens from the air. Vacuuming should be done regularly to avoid the accumulation of dust and dirt.
                </li>
                <li>
                Steam cleaning is an innovative cleaning method that removes dirt, mites and bacteria from the mattress. The steam that is used in this method penetrates the mattress, disinfecting it and removing impurities. After using this method, the mattress should be allowed to dry for at least 24 hours.
                </li>
            </ul>
            <p>
            Summary.
            Cleaning your mattress at home is crucial to maintaining hygiene and health. The mattress should be cleaned at least every 6 months..
            </p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <FirstScreenEn />
        <Lang />
        <Furnitura />
        <FiveSCreenEn />
        </div>
    );
}
export default ArticelTwo;