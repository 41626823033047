import React, { useState } from 'react';
import FirstScreenEn from '../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../FiveScreenEn/FiveScreen';
import './Services.css';
import onas from '../img/HopClean/onas.jpg';

import '../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>En</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/o-nas">Pl</Link>
          ) : (
            <Link to="/en/o-nas">En</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

function Onastext() {
  return (
    <div className="containerOnas">
      <h2>About Us</h2>
      <p className="onasP">
        Good morning dear customers, we are a cleaning company since 2008.
      </p>
      <p>
        A furniture cleaning company is an extremely important place in the
        market because it offers services that are necessary in everyday life.
        Cleaning furniture is a task that can be difficult to do at home,
        especially when dealing with heavy soiling or hazardous substances. That
        is why it is worth using the services of professionals who know how to
        deal with each type of furniture and provide them with proper cleaning.
      </p>
      <p>
        Our company deals with professional cleaning of upholstered furniture,
        including sofas, chairs, armchairs, beds and other upholstered
        furniture. Our services are aimed at both individual clients and
        companies that need furniture cleaning services in offices, hotels,
        restaurants and other public places.
      </p>
      <img src={onas} alt="o nas" />
      <p>
        As a company with many years of experience in the industry, we use only
        the highest quality tools and cleaning agents to ensure effective and
        safe cleaning. Our work is based on an individual approach to each order
        to ensure the best results for each piece of furniture and client.
      </p>
      <p>
        Our company offers a wide range of services such as surface cleaning,
        stain removal, odor removal, disinfection, vacuuming and much more. Each
        service is tailored to the individual needs of the client to ensure the
        best possible results.
      </p>
      <p>
        Cleaning upholstered furniture can be expensive, but our company offers
        competitive prices that depend on the size and type of furniture. Our
        prices are fair and transparent, meaning customers don't have to worry
        about hidden costs.
      </p>
      <p>
        We work with reputable furniture manufacturers, thanks to which we have
        knowledge about the different types of materials that furniture is made
        of and the best ways to clean them. In addition, our employees are
        trained in the latest cleaning methods and techniques to ensure the best
        results for our customers.
      </p>
      <p>
        As a furniture cleaning company, we are committed to keeping our
        customers clean, safe and satisfied with our services.
      </p>
      <p>
        We are not always able to send our employees to the order. If all
        employees are busy in our company, we use the help of third parties,
        namely our partners who carry out your orders.
      </p>
    </div>
  );
}
function Onas() {
  return (
    <div className="body_wrapper">
      <FirstScreenEn />
      <Lang />
      <Onastext />
      <FiveSCreenEn />
    </div>
  );
}
export default Onas;
