import regul from './../../img/HopClean/article5.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";

import React, {useState} from "react";
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';


function Lang () {
    const [isLang, setLang] = useState(false);
    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>En</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/articelfive'>Pl</Link> :<Link to='/en/articelfive'>En</Link>} </button>
        </div>
        </>
    )
}

function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Washing carpets and rugs</h2>
            <p>
            Washing carpets and rugs is an important activity that affects the cleanliness and hygiene of our homes. Despite regular vacuuming, various types of dirt can accumulate on these surfaces, which affect not only the aesthetics, but also our health. In this article, we will show you a few reasons why carpet cleaning is so important.
            </p>
            <p>
            Firstly, washing carpets and rugs helps to remove contaminants such as bacteria, viruses, mites, dust and pollen. These factors can affect our health, especially those with allergies and asthma. Regularly washing carpets and rugs can help reduce the occurrence of allergic reactions and prevent the spread of infectious diseases.
            </p>
            <p>
            Secondly, carpets and rugs are exposed to various types of stains and dirt, such as oil, coffee or wine. Over time, these stains become more difficult to remove. Regular washing can help remove these stains and keep your rug in good condition for longer.
            </p>
            <img className="quilon" src={regul} title='фурнітура' alt="фурнітура"/>
            <p>
            Thirdly, regular washing of carpets and rugs can help maintain their appearance and durability. Over time, these surfaces can accumulate contaminants that can affect their structure and durability. Washing removes these contaminants and improves the surface quality, which positively affects the appearance and durability of carpets and rugs.
            </p>
            <p>
            Fourthly, washing carpets and rugs is also a matter of smells. Over time, these surfaces can accumulate odors from the environment, especially in places where we smoke cigarettes, cook or breed animals. Regular washing removes these odors and refreshes the surfaces.
            </p>
            <p>
            To sum up, washing carpets and rugs is an important activity that affects the cleanliness and hygiene of our homes. Regular washing removes dirt, stains and odors and keeps carpets and rugs in good condition for longer. That is why it is worth taking care of regular washing to ensure a clean and healthy environment in our homes.
            </p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <FirstScreenEn />
        <Lang />
        <Furnitura />
        <FiveSCreenEn />
        </div>
    );
}
export default ArticelTwo;