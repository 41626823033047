import React, { useEffect, useState } from 'react';
import './LinkQuick.css';
import { Link } from 'react-router-dom';

function LinkQuick() {
  const [isScrollUp, setScrollUp] = useState(true);

  useEffect(() => {
    if (isScrollUp) {
      window.scroll({ top: 0 });
      setScrollUp(false);
    }
  }, [isScrollUp]);

  return (
    <div className="linkQuick">
      <ul>
        <li>
          <Link to="/">Główny</Link>
        </li>
        <li>
          <Link to="/o-nas">O nas</Link>
        </li>
        <li>
          <Link to="/cennik">Cennik</Link>
        </li>
        <li>
          <Link to="/galiria">Galeria</Link>
        </li>
        <li>
          <Link to="/kontakt">Kontakt</Link>
        </li>
        <li>
          <Link to="/articelone">Blog</Link>
        </li>
      </ul>
    </div>
  );
}

export default LinkQuick;
