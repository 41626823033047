import window from './../../../components/img/HopClean/updateArticles/foto4.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>En</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/articel21">Pl</Link>
          ) : (
            <Link to="/en/articel13">En</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

function Furnitura() {
  return (
    <>
      {' '}
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>How to clean your car ceiling yourself at home</h2>
            <p>
              Cleaning your car's ceiling may seem complicated, but with the
              right tools and techniques you can do it yourself at home.
            </p>
            <img className="window" src={window} title="window" alt="window" />
            <p>
              Here's a step-by-step guide on how to clean your car's ceiling to
              make it look like new.
            </p>
            <p>
              Tools and materials needed. Before you start cleaning, prepare the
              following things:
            </p>

            <ul className="decical-ol">
              <li>Vacuum cleaner with a soft brush</li>
              <li>Soft brush or sponge</li>
              <li> Microfiber cloths </li>
              <li>
                Upholstery cleaning product or a solution of soap and water
              </li>
              <li> Distilled water</li>
              <li> Water container</li>
              <li> Optional: upholstery stain remover</li>
            </ul>

            <p>
              Step 1: Preparation First, vacuum the ceiling of your car using a
              vacuum cleaner with a soft brush. Remove any loose debris such as
              dirt and dust. Make sure you do this gently so as not to damage
              the material.
            </p>
            <p>
              Step 2: Prepare the cleaning solution If you are using a special
              upholstery cleaner, follow the instructions on the package. If you
              don't have such a preparation, you can use a solution of soap and
              water. Mix a small amount of mild soap with distilled water in a
              container.
            </p>
            <p>
              Step 3: Testing the preparation Before you start cleaning the
              entire ceiling, test the selected preparation on a small,
              invisible area. Make sure it does not discolor or damage the
              material.
            </p>
            <p>
              Step 4: Clean the ceiling Apply a small amount of cleaning
              solution to a soft brush or sponge. Gently wipe the ceiling in
              circular motions. Remember not to soak the material, as excess
              moisture may lead to damage and mold.
            </p>
            <p>
              Step 5: Stain removal If there are difficult-to-remove stains on
              the ceiling, use an upholstery stain remover. Apply it directly to
              the stain and gently rub it in with a microfiber cloth. Leave the
              preparation for a few minutes and then wipe it with a clean, damp
              cloth.
            </p>
            <p>
              Step 6: Drying After cleaning, leave the car door open to allow
              the ceiling to dry. You can also use a fan to speed up this
              process. Make sure the ceiling is completely dry before closing
              the car door.
            </p>
            <p>
              Step 7: Final vacuuming Once the ceiling is dry, vacuum it again
              to remove any remaining dirt and cleaning agent. Use a soft brush
              to avoid damaging the material.
            </p>
            <p>
              Cleaning your car's ceiling can be time-consuming, but it's a task
              you can do yourself at home. Regularly cleaning the ceiling will
              help keep your car's interior clean and fresh. Thanks to our tips,
              your ceiling will look like new without the need to use
              professional cleaning services.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreenEn />
      <Lang />
      <Furnitura />
      <FiveSCreenEn />
    </div>
  );
}
export default ArticelTwo;
