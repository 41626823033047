import window from './../../img/HopClean/article7.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";
import React, {useState} from "react";
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';


function Lang () {
    const [isLang, setLang] = useState(false);
    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>En</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/articelseven'>Pl</Link> :<Link to='/en/articelseven'>En</Link>} </button>
        </div>
        </>
    )
}
function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Laundry of furniture on the plane</h2>
            <p>
            Would you like your furniture in Krakow to gain a new life? We offer an innovative service of washing furniture on the plane, which will give them a fresh look and refresh their interiors!
            </p>
            <p>
            Thanks to our specialized furniture washing, we can deliver professional cleaning directly to your home in Krakow and the surrounding area. Our advanced technology and experienced team will give your furniture a thorough and effective cleaning, with minimal disruption to your daily life.
            </p>
            <img className="window" src={window} title='window' alt="window"/>
            <p>
            Why is it worth using our furniture washing service on the plane in Krakow? Here are some reasons:
            </p>
            <ul className="windowraz">
                <li>Speed ​​and Convenience: Our aircraft are well-equipped and ready to go, so we can deliver a furniture wash service directly to your home in Krakow, saving you time and effort.</li>
                <li>
                Professionalism: Our experienced team will meticulously assess and clean your furniture using the latest techniques and cleaning agents, ensuring its condition and quality.
                </li>
                <li>
                Effectiveness: Thanks to our advanced cleaning methods, we are able to remove stubborn stains, dirt and odors from your furniture, restoring it to its original shine.
                </li>
                <li>Safety: Our cleaners are eco-friendly and safe for human and animal health, meaning your furniture will be properly cleaned without exposure to hazardous chemicals.</li>
                <li>Professional advice: Our team will be happy to provide you with professional advice on furniture care, so that you can enjoy its beauty for a long time.</li>
            </ul>
            <p>
            Don't wait! Bring your furniture to life in Krakow with our innovative in-flight furniture wash service. Contact us now to make an appointment and experience professional furniture washing in an unconventional way!
            </p>
            <p>We will not only renovate your home decor, but also ensure your comfort and safety thanks to our in-flight furniture washing service in Krakow. Our experienced team is ready to act to meet your expectations and meet the highest standards of service.</p>
            <p>You no longer have to worry about transporting furniture to a specialist plant or waiting for their return. Our in-flight furniture laundry service is fast, efficient and convenient. Our aircraft are equipped with advanced devices and cleaning agents that effectively remove stains, dirt and unpleasant odors from your furniture, restoring it to a fresh look.</p>
            <p>In addition, our cleaning products are ecological and safe, which means that they do not harm the environment or human and animal health. You can rest assured about the safety of your furniture, your health and the environment.</p>
            <p>Our team will meticulously assess the condition of your furniture and adapt the appropriate cleaning techniques to give it the best care. In addition, we provide professional advice on furniture care so that you can enjoy it for a long time.</p>
            <p>Bring your furniture to life and refresh your interiors with our innovative in-flight furniture washing service in Krakow. Contact us now to make an appointment and experience a professional approach to furniture washing in a unique way!</p>
            <p>Additionally, our Krakow plane furniture wash service is suitable for various types of furniture, such as sofas, armchairs, chairs, couches, mattresses and much more. Regardless of the type of fabric, shape or size of your furniture, our experienced team will effectively clean it of dirt, dust, stains or unpleasant odors.</p>
            <p>Thanks to our innovative approach to washing furniture on the plane, we can also adapt our service to your schedule and preferences. We can arrange for your furniture to be washed at a time that is convenient for you, minimizing disruption to your daily life.</p>
            <p>Our service of washing furniture on the plane is also ideal for people who value convenience and comfort. You don't have to worry about carrying heavy furniture or organizing transport. Our team will come with the right equipment and cleaning products to ensure you are fully satisfied with our service.</p>
            <p>The results of our in-flight furniture washing service in Krakow are impressive. Your furniture will gain a refreshed look, restoring its original splendor. You can enjoy clean and fragrant furniture that will add freshness and new life to your interior.</p>
            <p>To use our service of washing furniture on a plane in Krakow, just contact us by phone or via our website. Our friendly team will answer all your questions, provide details of the service and arrange a convenient date for you.</p>
            <p>Aby skorzystać z naszej usługi prania mebli w samolocie w Krakowie, wystarczy skontaktować się z nami telefonicznie lub za pośrednictwem naszej strony internetowej. Nasz przyjazny zespół odpowie na wszystkie Twoje pytania, przedstawi szczegóły usługi i umówi dogodny dla Ciebie termin.</p>
            <p>Our experienced staff will take care of your furniture using advanced cleaning techniques and ecological cleaning agents. You can be calm about the quality of the service, because we care about the highest level of professionalism and satisfaction of our customers.</p>
            <p>Washing furniture on the plane is an innovative solution that will save you time and effort while enjoying beautiful and clean furniture. Thanks to our service, your home decor will look like new, and you will be able to enjoy the comfort and freshness of your furniture.</p>
            <p>Do not hesitate any longer, use our service of washing furniture on the plane in Krakow and see for yourself about its reliability and uniqueness. Contact us now to make an appointment and experience a professional approach to furniture washing in an innovative way!</p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <FirstScreenEn />
        <Lang />
        <Furnitura />
        <FiveSCreenEn />
        </div>
    );
}
export default ArticelTwo;