import FiveScreen from '../../FiveScreen/FiveScreen';
import window from './../../img/HopClean/updateArticles/foto9.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FirstScreen from '../../FirstScreen/ContainerOne';
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>Pl</span>
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/en/articel16">En</Link>
          ) : (
            <Link to="/articel16">Pl</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}
function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Jak samodzielnie wyczyścić dywan w warunkach domowych</h2>
            <p>
              Dywany są ważnym elementem wystroju wnętrza, ale jednocześnie mogą
              gromadzić brud, kurz i plamy. Regularne czyszczenie dywanów nie
              tylko poprawia ich wygląd, ale także przyczynia się do zdrowszego
              środowiska domowego.
            </p>
            <img className="window" src={window} title="window" alt="window" />

            <p>
              {' '}
              Oto kilka sprawdzonych metod, jak samodzielnie wyczyścić dywan w
              domu.
            </p>
            <ul className="decical-ol">
              <li>
                Regularne odkurzanie. Regularne odkurzanie jest podstawą
                utrzymania dywanu w czystości. Powinno się to robić co najmniej
                raz w tygodniu, a w miejscach o dużym natężeniu ruchu nawet
                częściej. Odkurzanie usuwa powierzchniowy kurz, brud i alergeny.
              </li>
              <li>
                Czyszczenie plam na bieżąco. Plamy na dywanie należy usuwać
                natychmiast po ich powstaniu, aby zapobiec ich trwałemu
                wchłonięciu. Oto kilka domowych sposobów na różne rodzaje plam:
                <ul>
                  <li>
                    Plamy z kawy i herbaty: Zmieszaj wodę z octem w proporcji
                    1:1, nasącz roztworem ściereczkę i delikatnie przecieraj
                    plamę, zaczynając od zewnętrznych krawędzi do środka.{' '}
                  </li>
                  <li>
                    Plamy z wina: Na świeżą plamę posyp sól kuchenną, która
                    wchłonie większość płynu. Następnie przemyj miejsce
                    roztworem wody z octem.
                  </li>
                  <li>
                    Plamy tłuste: Posyp plamę mąką ziemniaczaną lub sodą
                    oczyszczoną, pozostaw na kilka godzin, a następnie odkurz.
                  </li>
                </ul>
              </li>
              <li>
                Domowe środki czystości. Do gruntownego czyszczenia dywanu można
                użyć domowych środków czystości:
                <ul>
                  <li>
                    Roztwór sody oczyszczonej i octu: Posyp dywan sodą
                    oczyszczoną, a następnie spryskaj go roztworem wody i octu
                    (proporcja 1:1). Pozostaw na kilka godzin, aż wszystko
                    wyschnie, a następnie odkurz. Soda oczyszczona neutralizuje
                    zapachy, a ocet pomaga usunąć brud.
                  </li>
                  <li>
                    Szamponowanie dywanu: Wymieszaj 1 łyżkę płynu do mycia
                    naczyń, 1 łyżkę białego octu i 2 filiżanki ciepłej wody.
                    Nasącz mieszanką gąbkę i delikatnie przemyj dywan. Po
                    wyschnięciu odkurz dywan.
                  </li>
                </ul>
              </li>
              <li>
                Parowe czyszczenie. Parowe czyszczenie jest skuteczną metodą
                głębokiego czyszczenia dywanów. Można wypożyczyć parownicę do
                dywanów lub zakupić urządzenie do użytku domowego. Parowe
                czyszczenie usuwa brud i bakterie z głębokich warstw dywanu,
                odświeżając go.
              </li>
              <li>
                Suszenie dywanu. Po każdym czyszczeniu dywan musi być dokładnie
                wysuszony, aby zapobiec rozwojowi pleśni i nieprzyjemnym
                zapachom. Jeśli to możliwe, wywietrz pomieszczenie lub użyj
                wentylatora, aby przyspieszyć proces suszenia.
              </li>
            </ul>
            <p>
              Samodzielne czyszczenie dywanu w warunkach domowych jest możliwe i
              skuteczne, jeśli zastosujesz się do powyższych wskazówek.
              Regularne odkurzanie, szybkie reagowanie na plamy oraz użycie
              domowych środków czystości pozwoli ci utrzymać dywan w doskonałym
              stanie przez długi czas. Jeśli dywan jest bardzo zabrudzony, warto
              rozważyć profesjonalne czyszczenie co jakiś czas.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <Lang />
      <Furnitura />
      <FiveScreen />
    </div>
  );
}
export default ArticelTwo;
