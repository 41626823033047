import window from './../../../components/img/HopClean/articel14.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>En</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/articel14">Pl</Link>
          ) : (
            <Link to="/en/articel13">En</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Cleaning leather upholstery</h2>
            <p>
              🌟 Restore the beauty of your leather furniture with our
              professional upholstery cleaning service! 🌟
            </p>
            <p>
              🛋️ Tired of stains and dirt on your beloved leather furniture?
              Don't fret! Our team of experts is ready to rejuvenate your sofa,
              armchair, or dining chairs to their former glory using advanced
              upholstery cleaning methods.
            </p>

            <img className="window" src={window} title="window" alt="window" />
            <p>🌈 Experience the difference with our services:</p>
            <ul className="windowraz">
              <li>
                Professional Approach: Our specialists have years of experience
                working with leather furniture and utilize the latest techniques
                to ensure the best results.
              </li>
              <li>
                Safety for Your Furniture: With our gentle cleaning agents and
                professional knowledge, you can rest assured that your furniture
                is in safe hands.
              </li>
              <li>
                Effective Stain Removal: Whether it's beverage spills, grease,
                or other substances, our cleaning methods effectively eliminate
                even the toughest stains.
              </li>
              <li>
                Protection and Refreshment: In addition to cleaning, our
                services include conditioning and refreshing the leather,
                prolonging its lifespan and maintaining its beautiful
                appearance.
              </li>
              <li>
                Convenience and Flexibility: We offer convenient scheduling
                options, and our services are flexible to accommodate your needs
                and preferences.
              </li>
            </ul>
            <p>
              Don't wait any longer! Take advantage of our leather furniture
              upholstery cleaning service and enjoy their pristine appearance
              today! 🌟
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreenEn />
      <Lang />
      <Furnitura />
      <FiveSCreenEn />
    </div>
  );
}
export default ArticelTwo;
