import FiveScreen from '../../FiveScreen/FiveScreen';
import window from './../../img/HopClean/articel11.jpg';
import window2 from '../../img/HopClean/articel112.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import FirstScreen from "../../FirstScreen/ContainerOne";
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';


function Lang () {
    const [isLang, setLang] = useState(false);
    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>Pl</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/en/articel11'>En</Link> :<Link to='/articel11'>Pl</Link>} </button>
        </div>
        </>
    )
}
function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Pranie tapicerki samochodowej </h2>
            <p>
            Tapicerka samochodowa to jedna z części pojazdu, która najszybciej ulega zabrudzeniu. Codzienne użytkowanie, kontakt z ciałem człowieka oraz zanieczyszczenia z zewnątrz, powodują, że tapicerka szybko traci swój pierwotny wygląd i kolor. Dlatego pranie tapicerki samochodowej jest tak ważne i powinno być regularnie przeprowadzane. W tym artykule przedstawiamy kilka powodów, dla których pranie tapicerki samochodowej jest tak istotne.
            </p>
            <p>
            Po pierwsze, regularne pranie tapicerki samochodowej wpływa na higienę i zdrowie kierowcy oraz pasażerów. Tapicerka samochodowa może gromadzić różnego rodzaju zanieczyszczenia, takie jak kurz, bakterie, wirusy, pleśnie czy roztocza, które mogą wpływać na nasze zdrowie i samopoczucie. Dlatego ważne jest, aby regularnie przeprowadzać pranie, które pozwoli na usunięcie tych zanieczyszczeń.
            </p>
            <img className="window" src={window} title='window' alt="window"/>
            <p>
            Po drugie, pranie tapicerki samochodowej wpływa na estetykę i funkcjonalność samochodu. Czysta i pachnąca tapicerka sprawia, że samochód wygląda bardziej estetycznie i przyjemniej się w nim przebywa. Ponadto, zanieczyszczenia na tapicerce mogą wpływać na trwałość i funkcjonalność materiału, co w dłuższej perspektywie może prowadzić do konieczności wymiany tapicerki.
            </p>
            <p>
            Po trzecie, pranie tapicerki samochodowej pozwala na usunięcie trudnych plam i zabrudzeń. Tapicerka samochodowa jest narażona na różnego rodzaju plamy, takie jak plamy z kawy, herbaty, soku czy oleju. Te plamy mogą być trudne do usunięcia za pomocą zwykłych środków czystości, dlatego warto skorzystać z profesjonalnego prania, które pozwoli na dokładne usunięcie nawet najtrudniejszych plam.
            </p>
           <p>Po czwarte, pranie tapicerki samochodowej pozwala na usunięcie nieprzyjemnych zapachów. Samochód jest narażony na różnego rodzaju zapachy, takie jak zapach papierosów, jedzenia czy wilgoci. Te zapachy mogą być trudne do usunięcia, dlatego warto skorzystać z profesjonalnego prania, które pozwoli na usunięcie nieprzyjemnych zapachów z tapicerki samochodowej.</p>
            <p>Podsumowując, pranie tapicerki samochodowej jest niezwykle ważne i powinno być regularnie przeprowadzane. Dzięki regularnemu praniu, zapewnimy sobie i naszym pasażerom czyste i bezpieczne środowisko w samochodzie, zachowamy estetykę i funkcjonalność tapicerki.</p>
            <img className="window" src={window2} title='window' alt="window"/>
            <p>Kabina ciężarówki to miejsce, w którym kierowcy spędzają wiele godzin dziennie. Dlatego ważne jest, aby kabina była czysta i przyjemna do przebywania. Jednym z najlepszych sposobów na utrzymanie czystości i higieny w kabinie ciężarówki jest regularne pranie. W tym artykule przedstawiamy kilka powodów, dla których pranie kabiny ciężarówki jest tak istotne. </p>
            <p>Po pierwsze, regularne pranie kabiny ciężarówki wpływa na higienę i zdrowie kierowcy. Kabina ciężarówki może gromadzić różnego rodzaju zanieczyszczenia, takie jak kurz, bakterie, wirusy, pleśnie czy roztocza, które mogą wpływać na nasze zdrowie i samopoczucie. Dlatego ważne jest, aby regularnie przeprowadzać pranie, które pozwoli na usunięcie tych zanieczyszczeń.</p>
            <p>Po drugie, pranie kabiny ciężarówki wpływa na estetykę i funkcjonalność pojazdu. Czysta kabina sprawia, że ciężarówka wygląda bardziej estetycznie i przyjemniej się w niej przebywa. Ponadto, zanieczyszczenia w kabinie mogą wpływać na trwałość i funkcjonalność materiału, co w dłuższej perspektywie może prowadzić do konieczności wymiany kabiny.</p>
            <p>Po trzecie, pranie kabiny ciężarówki pozwala na usunięcie trudnych plam i zabrudzeń. Kierowcy ciężarówek często podróżują w trudnych warunkach, co oznacza, że kabina jest narażona na różnego rodzaju plamy, takie jak plamy z oleju, smaru czy innych substancji. Te plamy mogą być trudne do usunięcia za pomocą zwykłych środków czystości, dlatego warto skorzystać z profesjonalnego prania, które pozwoli na dokładne usunięcie nawet najtrudniejszych plam.</p>
            <p>Po czwarte, pranie kabiny ciężarówki pozwala na usunięcie nieprzyjemnych zapachów. Kierowcy ciężarówek często transportują różnego rodzaju towary, co oznacza, że kabina może nabierać zapachów z ładunku. Te zapachy mogą być trudne do usunięcia, dlatego warto skorzystać z profesjonalnego prania, które pozwoli na usunięcie nieprzyjemnych zapachów z kabiny ciężarówki.</p>
            <p>Podsumowując, pranie kabiny ciężarówki jest niezwykle ważne i powinno być regularnie przeprowadzane. Dzięki regularnemu praniu, zapewnimy sobie czyste i bezpieczne środowisko w kabinie ciężarówki, zachowamy estetykę i funkcjonalność kabiny, a także zapach.</p>
            <p></p>
            <p></p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <FirstScreen />
        <Lang />
        <Furnitura />
        <FiveScreen />
        </div>
    );
}
export default ArticelTwo;