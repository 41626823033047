import React from 'react';
// import Choose from './Choose';
import './ContainerElements.css';
import { Link } from 'react-router-dom';
import Service from './Service';

function ContainerH2H3() {
  return (
    <>
      <h3>Our services and their cost</h3>
      <h1 className="service-en-h1">
        Washing furniture and car upholstery, carpets, couches, chairs ...
      </h1>
    </>
  );
}
function Check() {
  return (
    <div className="check">
      <li>
        <Link to="/en/cennik">More details...</Link>
      </li>
    </div>
  );
}
function ContainerElements() {
  return (
    <div className="container">
      <ContainerH2H3 />
      <Service />
      {/* <Choose /> */}
      <Check />
    </div>
  );
}

export default ContainerElements;
