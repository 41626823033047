import './Gallery.css';
import {
  ReactVideoPlayerYoutube,
  ReactVideoPlayerYoutube2,
  ReactVideoPlayerYoutube3,
} from '../../UI/ReactVideoPlayer2/ReactVideoPlayer2';

function VideoGallery() {
  return (
    <>
      <div className="main__third-services">
        <div className="main__gallery">
          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayerYoutube />
            </div>
          </div>

          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayerYoutube2 />
            </div>
          </div>
          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayerYoutube3 />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default VideoGallery;
