import SwiperReviews from './Swiper';
import './Reviews.css';

function ReviewsEn() {
  return (
    <div className="reviews">
      <div className="reviews__container">
        <h3 className="reviews__title">Customers opinion</h3>
        <div>
          <SwiperReviews />
        </div>
      </div>
    </div>
  );
}

export default ReviewsEn;
