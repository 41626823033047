import { Navigation, Scrollbar, A11y } from 'swiper/modules';
import { useLayoutEffect, useState } from 'react';
import {
  ReactVideoPlayerYoutube,
  ReactVideoPlayerYoutube2,
  ReactVideoPlayerYoutube3,
} from '../../UI/ReactVideoPlayer2/ReactVideoPlayer2';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './SwiperVideoGallery.css';
import VideoGallery from '../VideoGallary/Gallery';
import { Link } from 'react-router-dom';
import youtubemainpage from '../img/youtubeforgallery.png';

function SwiperVideo2() {
  return (
    <Swiper
      modules={[Navigation, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1}
      navigation
      scrollbar={{ draggable: true }}
    >
      <SwiperSlide>
        <div className="videoplayeryoute">
          <ReactVideoPlayerYoutube />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="videoplayeryoute">
          <ReactVideoPlayerYoutube2 />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="videoplayeryoute">
          <ReactVideoPlayerYoutube3 />
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

function SwiperVideoGallery() {
  const [isHeader, setIsHeader] = useState(false);

  useLayoutEffect(() => {
    if (window.innerWidth < 500) {
      setIsHeader(true);
    } else {
      setIsHeader(false);
    }
  }, [setIsHeader]);
  return (
    <>
      <div className="container container-video">
        {!isHeader ? (
          <>
            <VideoGallery />
          </>
        ) : (
          <>
            <SwiperVideo2 />
          </>
        )}
        <div className="youtube_main-page">
          <h3>Więcej naszych prac:</h3>
          <Link
            to={'https://www.youtube.com/@HofClean/videos'}
            alt="Więcej naszych prac youtube"
            target="_blank"
          >
            <img src={youtubemainpage} alt="youtubemainpage" />
          </Link>
        </div>
      </div>
    </>
  );
}

export default SwiperVideoGallery;
