import FiveScreen from '../../FiveScreen/FiveScreen';
import window from './../../img/HopClean/updateArticles/foto8.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FirstScreen from '../../FirstScreen/ContainerOne';
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>Pl</span>
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/en/articel17">En</Link>
          ) : (
            <Link to="/articel17">Pl</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}
function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>
              Jak samodzielnie wyczyścić meble tapicerowane w domowych
              warunkach?
            </h2>
            <p>
              Czyszczenie mebli tapicerowanych może wydawać się trudnym
              zadaniem, ale z odpowiednimi narzędziami i metodami, można to
              zrobić skutecznie w domowych warunkach.
            </p>
            <img className="window" src={window} title="window" alt="window" />

            <p>
              Oto krok po kroku, jak zadbać o swoje kanapy, fotele i inne meble
              tapicerowane.
            </p>
            <ul className="decical-ol">
              <li>
                Przygotowanie do czyszczenia. Materiały i narzędzia:
                <ul>
                  <li>Odkurzacz z nasadką do tapicerki</li>
                  <li>Szczotka z miękkim włosiem</li>
                  <li>Ściereczki z mikrofibry</li>
                  <li>Miska z wodą</li>
                  <li>
                    Delikatny detergent lub specjalny środek do czyszczenia
                    tapicerki
                  </li>
                  <li>Soda oczyszczona</li>
                  <li>Ocet</li>
                  <li>Woda destylowana</li>
                </ul>
              </li>
              <li>
                Odkurzanie. Zacznij od dokładnego odkurzenia mebli. Użyj
                odkurzacza z nasadką do tapicerki, aby usunąć kurz, sierść
                zwierząt i inne luźne zanieczyszczenia. Pamiętaj, aby dotrzeć do
                wszystkich zakamarków i szczelin.
              </li>
              <li>
                Usuwanie plam. Świeże plamy:
                <ul>
                  <li>
                    Jak najszybciej po ich powstaniu, użyj ściereczki z
                    mikrofibry, aby delikatnie wchłonąć płyn. Nie pocieraj, aby
                    nie rozprowadzić plamy.
                  </li>
                  <li>
                    Możesz zastosować roztwór wody z odrobiną detergentu. Namocz
                    ściereczkę i delikatnie przemyj plamę.
                  </li>
                </ul>
                Starsze plamy:
                <ul>
                  <li>
                    W przypadku trudniejszych plam, jak czerwone wino czy kawa,
                    możesz użyć mieszaniny wody i octu (proporcja 1:1). Nałóż
                    roztwór na plamę, a następnie delikatnie przetrzyj.
                  </li>
                  <li>
                    Soda oczyszczona również sprawdza się przy usuwaniu zapachów
                    i plam. Posyp plamę sodą, zostaw na kilka godzin, a
                    następnie odkurz.
                  </li>
                </ul>
              </li>
              <li>
                Ogólne czyszczenie.Przygotuj roztwór czyszczący:
                <ul>
                  <li>
                    W misce wymieszaj ciepłą wodę z delikatnym detergentem lub
                    specjalnym środkiem do czyszczenia tapicerki.
                  </li>
                  <li>
                    Namocz ściereczkę z mikrofibry w roztworze, dobrze ją
                    wykręć, aby była tylko lekko wilgotna.
                  </li>
                  <li>
                    Przetrzyj całą powierzchnię mebla, zwracając szczególną
                    uwagę na miejsca o większym zabrudzeniu.
                  </li>
                </ul>
              </li>
              <li>
                Płukanie. Po oczyszczeniu tapicerki detergentem, ważne jest, aby
                usunąć resztki środków czyszczących. Użyj czystej ściereczki
                namoczonej w wodzie destylowanej, aby przemyć mebel.
              </li>
              <li>
                Suszenie. Upewnij się, że meble są dobrze osuszone. Otwórz okna
                lub włącz wentylator, aby przyspieszyć suszenie. Nigdy nie
                pozostawiaj mebli wilgotnych, ponieważ może to prowadzić do
                powstania pleśni i nieprzyjemnych zapachów.
              </li>
              <li>
                Pielęgnacja i konserwacja. Aby tapicerka długo wyglądała jak
                nowa, warto regularnie ją odkurzać i czyścić. Unikaj
                bezpośredniego nasłonecznienia, które może powodować blaknięcie
                materiału. Pamiętaj, że niektóre materiały wymagają
                specjalistycznej pielęgnacji, dlatego zawsze warto sprawdzić
                etykietę producenta mebla. W razie wątpliwości, można
                skonsultować się z profesjonalistą.
              </li>
            </ul>
            <p>
              Dzięki tym prostym krokom, Twoje meble tapicerowane będą zawsze
              czyste i zadbane, co przedłuży ich żywotność i poprawi estetykę
              wnętrza.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <Lang />
      <Furnitura />
      <FiveScreen />
    </div>
  );
}
export default ArticelTwo;
