import React from "react";
import { useLocation } from "react-router-dom";
import './Services.css';
import { Link } from "react-router-dom";

function Whoops404() {
    let location = useLocation();
    console.log(location);
    return (
        <div className="error404">
            <h1 className="nofindresurs">
                Warning! <span>Określona ścieżka jest nieaktualna lub nie została znaleziona...</span>
            </h1>
            <p>Aby przejść do serwisu należy kliknąć we wskazany link<span className="linkpath"> <Link to="/">hofclean.pl</Link></span></p>
        </div>
    );
}
export default Whoops404;