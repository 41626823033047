import window from './../../img/HopClean/articel12.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";
import React, {useState} from "react";
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';


function Lang () {
    const [isLang, setLang] = useState(false);
    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>En</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/articel12'>Pl</Link> :<Link to='/en/articel12'>En</Link>} </button>
        </div>
        </>
    )
}
function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Laundry of prams and car seats</h2>
            <p>
            Washing prams and car seats is an important activity that affects the hygiene and safety of our children. Despite care for cleanliness and regular washing, various types of contamination can accumulate on these surfaces, which affect not only the aesthetics, but also the health of our children. In this article, we will show you a few reasons why washing prams and car seats is so important.
            </p>
            <p>
            Firstly, infants are particularly sensitive to pollution. Their skin is delicate and prone to irritation, and the immune system is not yet fully developed. Therefore, it is important to protect them from bacteria, viruses and other harmful substances that may be on the surfaces of strollers and car seats.
            </p>
            <img className="window" src={window} title='window' alt="window"/>
            <p>
            Secondly, prams and car seats are exposed to various types of stains and dirt, such as milk, food or liquids. This dirt can affect the durability and functionality of prams and car seats and negatively affect the health of our children. Regular washing removes these stains and keeps prams and car seats in good condition for a longer period of time.
            </p>
            <p>
            Third, prams and car seats can have many components that are difficult to clean in-situ, such as seat belts, frames, and cushions. Washing prams and car seats allows for thorough cleaning of all elements, which affects the hygiene and safety of our children.
            </p>
            <p>Fourthly, regular washing of prams and car seats allows you to maintain a fresh and pleasant smell, which is especially important in the case of prams and car seats, which are often exposed to various types of smells, e.g. from food or liquids. </p>
            <p>To sum up, washing prams and car seats is an important activity that affects the hygiene and safety of our children. Regular washing allows you to remove dirt and stains, keep the elements of prams and car seats in good condition and keep a fresh and pleasant smell. That is why it is worth taking care of regular washing of prams and car seats to keep our children clean.</p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <FirstScreenEn />
        <Lang />
        <Furnitura />
        <FiveSCreenEn />
        </div>
    );
}
export default ArticelTwo;