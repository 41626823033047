import instrum from './../../img/HopClean/article6.jpg';
import instrum2 from './../../img/HopClean/article62.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";

import React, {useState} from "react";
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';


function Lang () {
    const [isLang, setLang] = useState(false);
    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>En</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/articelsix'>Pl</Link> :<Link to='/en/articelsix'>En</Link>} </button>
        </div>
        </>
    )
}

function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Cleaning mattresses in kindergartens</h2>
            <p>
            Mattresses in kindergartens are heavily used by children for most of the day. For this reason, it is essential to maintain good hygiene and clean mattresses regularly to ensure the safety and health of children. In this article, we will discuss how often you should clean the mattress in the nursery and what methods you should use.
            </p>
          <p>
          First of all, keep in mind that nursery mattresses are exposed to all sorts of contaminants, including urine, sweat, pus and bacteria. For this reason, mattresses should be cleaned regularly to avoid the growth of mold, mites and bacteria. According to the recommendations of specialists, mattresses should be cleaned at least twice a year.
            </p>
            <img className="quilon" src={instrum2} title='фурнітура' alt="фурнітура"/>
            <p>
            One way to keep your mattress clean is to regularly vacuum it to remove dust and dirt. For this purpose, you can use a vacuum cleaner with a HEPA filter, which removes allergens and dust from the air, and also removes dirt from the surface of the mattress.
            </p>
          
            <p>
            Another method of cleaning the mattress is to use disinfectants, such as carpet and furniture cleaners. However, keep in mind that some cleaning products can be harmful to children, so it is important to choose products that are safe for children and do not contain harmful substances.
            </p>
            <p>
            An alternative method of cleaning the mattress is to use steam. Steam allows you to remove both dirt and bacteria without the use of chemicals. However, if you use steam, make sure the mattress is properly dry before using it again.
            </p>
            <img className="quilon" src={instrum} title='фурнітура' alt="фурнітура"/>
            <p>
            In the case of more serious dirt and stains, it is best to use the services of specialist cleaning companies that have the right tools and cleaning agents. These companies often use professional mattress cleaning machines, which allows you to remove more difficult stains and dirt.
            </p>
          
            <p>
            In conclusion, mattresses in kindergartens should be vacuumed and disinfected regularly to keep them clean and avoid the growth of mold, mites and bacteria. It is recommended to clean the mattresses at least twice a year, and in the case of more serious dirt, use the services of specialized cleaning companies.
            </p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <FirstScreenEn />
        <Lang />
        <Furnitura />
        <FiveSCreenEn />
        </div>
    );
}
export default ArticelTwo;