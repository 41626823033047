import React, { useRef } from "react";
import { createPortal } from 'react-dom';
import './BackDropForm.css';
import Vector from '../img/vector.png';

function createWrapperAndAppendToBody (wrapperId) {
    const wrapperElement = document.createElement('div');
    wrapperElement.setAttribute('id', wrapperId);
    document.body.appendChild(wrapperElement);
    return wrapperElement;
}

function ReactPortal({children, wrapperId = 'react-portal-wrapper'}) {
let element = document.getElementById('wrapperId');
if (!element) {
    element = createWrapperAndAppendToBody(wrapperId);
}
return createPortal(children, element);
}

function BackDropForm ({isOpen, handleClick, setOpen}) {
    const nameRef = useRef();
    const emailRef = useRef();
    const telRef = useRef();

    if (!isOpen) return null;
    
    const submit = e => {
        e.preventDefault();
        const user = {
            key: 'user',
            name: nameRef.current.value,
            email: emailRef.current.value,
            tel: telRef.current.value
        };

        if (isNaN(user.tel)) {
            alert("Wprowadzono nieprawidłowe dane. Numer telefonu musi składać się wyłącznie z cyfr. ");
        }else {
            nameRef.current.value = '';
            emailRef.current.value = '';
            telRef.current.value = '';
            alert(`Formularz został pomyślnie wysłany. Skontaktujemy się z Tobą wkrótce.`);
            localStorage.setItem(user.name, JSON.stringify({email: user.email, tel: user.tel, date: new Date().toString()}));
            handleClick();
        }
    }

    return (
        <ReactPortal> 
        {isOpen && (<div onClick={setOpen} className="back-drop" id="back-drop"></div>)}
         <form onSubmit={submit}>
        <div className="divImg">
        <img onClick={handleClick} id="close" className="close" src={Vector} alt="vector"/>
        </div>
        <h2 id="h2">Wypełnij formularz,<br/>zamówić połączenie</h2>
        <input ref={nameRef} type="text" placeholder="Twoje imię..." required/>
        <input ref={emailRef} type="email" placeholder="admin.gmail.com" required/>
        <input ref={telRef} type="text" placeholder="Twój telefon..." required/>
        <input type='submit' className="btn" value="Zamów usługę"></input>
        </form>
        </ReactPortal>     
    );
}

export default BackDropForm;