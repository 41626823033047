import FiveScreen from '../../FiveScreen/FiveScreen';
import window from './../../img/HopClean/article7.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import FirstScreen from "../../FirstScreen/ContainerOne";
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';


function Lang () {
    const [isLang, setLang] = useState(false);
    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>Pl</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/en/articelseven'>En</Link> :<Link to='/articelseven'>Pl</Link>} </button>
        </div>
        </>
    )
}

function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Pranie mebli w samolocie </h2>
            <p>
            Chciałbyś, żeby Twoje meble w Krakowie zyskały nowe życie? Oferujemy innowacyjną usługę prania mebli w samolocie, która przyniesie im świeży wygląd i odświeży ich wnętrza!
            </p>
            <p>
           Dzięki naszym specjalistycznym praniem mebli, możemy dostarczyć profesjonalne czyszczenie bezpośrednio do Twojego domu w Krakowie i okolicach. Nasza zaawansowana technologia i doświadczony zespół zapewnią Twoim meblom gruntowne i efektywne czyszczenie, przy minimalnym zakłócaniu Twojego codziennego życia.
            </p>
            <img className="window" src={window} title='window' alt="window"/>
            <p>
            Dlaczego warto skorzystać z naszej usługi prania mebli w samolocie w Krakowie? Oto kilka powodów:
            </p>
            <ul className="windowraz">
                <li>Szybkość i wygoda: Nasze samoloty są dobrze wyposażone i gotowe do działania, dzięki czemu możemy dostarczyć usługę prania mebli bezpośrednio do Twojego domu w Krakowie, oszczędzając Twój czas i wysiłek.</li>
                <li>
                Profesjonalizm: Nasz doświadczony zespół skrupulatnie oceni i oczyści Twoje meble z użyciem najnowocześniejszych technik i środków czyszczących, dbając o ich stan i jakość.
                </li>
                <li>
                Skuteczność: Dzięki naszym zaawansowanym metodom czyszczenia, jesteśmy w stanie usunąć uporczywe plamy, brudy i zapachy z Twoich mebli, przywracając im ich pierwotny blask.
                </li>
                <li>Bezpieczeństwo: Nasze środki czyszczące są ekologiczne i bezpieczne dla zdrowia ludzi i zwierząt, co oznacza, że Twoje meble zostaną odpowiednio oczyszczone, nie narażając na niebezpieczne chemikalia.</li>
                <li>Profesjonalne doradztwo: Nasz zespół chętnie udzieli Ci profesjonalnych porad dotyczących pielęgnacji mebli, abyś mógł cieszyć się ich pięknem przez długi czas.</li>
            </ul>
            <p>
            Nie czekaj! Ożyw swoje meble w Krakowie dzięki naszej innowacyjnej usłudze prania mebli w samolocie. Skontaktuj się z nami już teraz, aby umówić się na termin i doświadczyć profesjonalnego prania mebli w niekonwencjonalny sposób!
            </p>
            <p>Nie tylko odnowimy Twój domowy wystrój, ale także zapewnimy Ci wygodę i bezpieczeństwo, dzięki naszej usłudze prania mebli w samolocie w Krakowie. Nasz doświadczony zespół jest gotowy do działania, aby spełnić Twoje oczekiwania i sprostać najwyższym standardom usług.</p>
            <p>Nie musisz już martwić się o przewożenie mebli do specjalistycznego zakładu czy czekanie na ich zwrot. Nasza usługa prania mebli w samolocie jest szybka, efektywna i wygodna. Nasze samoloty są wyposażone w zaawansowane urządzenia i środki czyszczące, które skutecznie usuwają plamy, brudy i nieprzyjemne zapachy z Twoich mebli, przywracając im świeży wygląd.</p>
            <p>Ponadto, nasze środki czyszczące są ekologiczne i bezpieczne, co oznacza, że nie szkodzą środowisku ani zdrowiu ludzi i zwierząt. Możesz być spokojny o bezpieczeństwo swoich mebli oraz swoje zdrowie i środowisko.</p>
            <p>Nasz zespół skrupulatnie oceni stan Twoich mebli i dostosuje odpowiednie techniki czyszczenia, aby zapewnić im najlepszą pielęgnację. Ponadto, służymy profesjonalnym doradztwem w zakresie pielęgnacji mebli, abyś mógł cieszyć się nimi przez długi czas.</p>
            <p>Ożyw swoje meble i odśwież swoje wnętrza dzięki naszej innowacyjnej usłudze prania mebli w samolocie w Krakowie. Skontaktuj się z nami już teraz, aby umówić się na termin i doświadczyć profesjonalnego podejścia do prania mebli w wyjątkowy sposób!</p>
            <p>Dodatkowo, nasza usługa prania mebli w samolocie w Krakowie jest odpowiednia dla różnego rodzaju mebli, takich jak sofy, fotele, krzesła, kanapy, materace i wiele innych. Bez względu na rodzaj tkaniny, kształt czy rozmiar Twoich mebli, nasz doświadczony zespół skutecznie oczyści je z brudu, kurzu, plam czy nieprzyjemnych zapachów.</p>
            <p>Dzięki naszemu innowacyjnemu podejściu do prania mebli w samolocie, możemy również dostosować naszą usługę do Twojego harmonogramu i preferencji. Możemy zorganizować pranie mebli w dogodnym dla Ciebie terminie, minimalizując zakłócenia w Twoim codziennym życiu.</p>
            <p>Nasza usługa prania mebli w samolocie jest również idealna dla osób, które cenią sobie wygodę i komfort. Nie musisz obawiać się przewożenia ciężkich mebli ani organizowania transportu. Nasz zespół przyjedzie z odpowiednim sprzętem i środkami czyszczącymi, aby zapewnić Ci pełne zadowolenie z naszej usługi.</p>
            <p>Wyniki naszej usługi prania mebli w samolocie w Krakowie są imponujące. Twoje meble zyskają odświeżony wygląd, przywracając im ich pierwotny blask. Możesz cieszyć się czystymi i pachnącymi meblami, które dodadzą świeżości i nowego życia Twojemu wnętrzu.</p>
            <p>Nie zwlekaj dłużej! Skorzystaj z naszej innowacyjnej usługi prania mebli w samolocie w Krakowie i przekonaj się o jej profesjonalizmie, wygodzie i efektywności. Skontaktuj się z nami już teraz, aby umówić się na termin i odświeżyć swoje meble w najnowocześniejszy sposób!</p>
            <p>Aby skorzystać z naszej usługi prania mebli w samolocie w Krakowie, wystarczy skontaktować się z nami telefonicznie lub za pośrednictwem naszej strony internetowej. Nasz przyjazny zespół odpowie na wszystkie Twoje pytania, przedstawi szczegóły usługi i umówi dogodny dla Ciebie termin.</p>
            <p>Nasz doświadczony personel zadba o Twoje meble, używając zaawansowanych technik czyszczenia i ekologicznych środków czyszczących. Możesz być spokojny o jakość usługi, ponieważ dbamy o najwyższy poziom profesjonalizmu i zadowolenie naszych klientów.</p>
            <p>Pranie mebli w samolocie to innowacyjne rozwiązanie, które pozwoli Ci zaoszczędzić czas i wysiłek, jednocześnie ciesząc się pięknymi i czystymi meblami. Dzięki naszej usłudze, Twój domowy wystrój będzie wyglądał jak nowy, a Ty będziesz mógł cieszyć się komfortem i świeżością swoich mebli.</p>
            <p>Nie zwlekaj dłużej, skorzystaj z naszej usługi prania mebli w samolocie w Krakowie i przekonaj się o jej niezawodności i wyjątkowości. Skontaktuj się z nami już teraz, aby umówić się na termin i doświadczyć profesjonalnego podejścia do prania mebli w innowacyjny sposób!.</p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <FirstScreen />
        <Lang />
        <Furnitura />
        <FiveScreen />
        </div>
    );
}
export default ArticelTwo;