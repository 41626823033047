import Foto1 from '../img/HopClean/newphoto1.jpg';
import Foto2 from '../img/HopClean/newphoto2.jpg';
import Foto3 from '../img/HopClean/newphoto3.jpg';
import Foto4 from '../img/HopClean/newphoto4.jpg';
import Foto5 from '../img/HopClean/newphoto5.jpg';
import Foto6 from '../img/HopClean/newphoto6.jpg';
import Foto7 from '../img/HopClean/newphoto7.jpg';
import Foto8 from '../img/HopClean/newphoto8.jpg';
import Foto9 from '../img/HopClean/newphoto9.jpg';
import Foto10 from '../img/HopClean/newphoto10.jpg';
import Foto11 from '../img/HopClean/newphoto11.jpg';
import Foto12 from '../img/HopClean/newphoto12.jpg';
import Foto13 from '../img/HopClean/newphoto13.jpg';
import Foto14 from '../img/HopClean/newphoto14.jpg';
import Foto15 from '../img/HopClean/newphoto15.jpg';
import Foto16 from '../img/HopClean/newphoto16.jpg';
import React, {useState} from "react";
import FirstScreen from "../FirstScreen/ContainerOne";
import FiveSCreen from '../FiveScreen/FiveScreen';
import '../Pages/Services.css';

import '../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Lang () {
    const [isLang, setLang] = useState(false);
    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>Pl</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/en/galiria'>En</Link> :<Link to='/galiria'>Pl</Link>} </button>
        </div>
        </>
    )
}

function Imgphoto() {
    const [isPhoto1, setPhoto1] = useState(false);
    const [isPhoto2, setPhoto2] = useState(false);
    const [isPhoto3, setPhoto3] = useState(false);
    const [isPhoto4, setPhoto4] = useState(false);
    const [isPhoto5, setPhoto5] = useState(false);
    const [isPhoto6, setPhoto6] = useState(false);
    const [isPhoto7, setPhoto7] = useState(false);
    const [isPhoto8, setPhoto8] = useState(false);
    const [isPhoto9, setPhoto9] = useState(false);
    const [isPhoto10, setPhoto10] = useState(false);
    const [isPhoto11, setPhoto11] = useState(false);
    const [isPhoto12, setPhoto12] = useState(false);
    const [isPhoto13, setPhoto13] = useState(false);
    const [isPhoto14, setPhoto14] = useState(false);
    const [isPhoto15, setPhoto15] = useState(false);
    const [isPhoto16, setPhoto16] = useState(false);

    const fullPhoto1 = () => {
          if (window.innerWidth <= 615) {
           setPhoto1(false);
           return;
          }
        setPhoto1(isPhoto1 => !isPhoto1);

    }
    const fullPhoto2 = () => {
     if (window.innerWidth <= 615) {
          setPhoto2(false);
           return;
         }
         setPhoto2(isPhoto2 => !isPhoto2);
    }
    const fullPhoto3 = () => {
     if (window.innerWidth <= 615) {
          setPhoto3(false);
           return;
         }
         setPhoto3(isPhoto3 => !isPhoto3);
    }
    const fullPhoto4 = () => {
     if (window.innerWidth <= 615) {
          setPhoto4(false);
          return;
         }
         setPhoto4(isPhoto4 => !isPhoto4);
    }
    const fullPhoto5 = () => {
     if (window.innerWidth <= 615) {
          setPhoto5(false);
          return;
         }
         setPhoto5(isPhoto5 => !isPhoto5);
    }
    const fullPhoto6 = () => {
     if (window.innerWidth <= 615) {
          setPhoto6(false);
          return;
         }
         setPhoto6(isPhoto6 => !isPhoto6);
    }
    const fullPhoto7 = () => {
     if (window.innerWidth <= 615) {
          setPhoto7(false);
          return;
         }
         setPhoto7(isPhoto7 => !isPhoto7);
    }
    const fullPhoto8 = () => {
     if (window.innerWidth <= 615) {
          setPhoto8(false);
          return;
         }
         setPhoto8(isPhoto8 => !isPhoto8);
    }
    const fullPhoto9 = () => {
     if (window.innerWidth <= 615) {
          setPhoto9(false);
          return;
         }
         setPhoto9(isPhoto9 => !isPhoto9);
    }
    const fullPhoto10 = () => {
     if (window.innerWidth <= 615) {
          setPhoto10(false);
           return;
         }
         setPhoto10(isPhoto10 => !isPhoto10);
    }
    const fullPhoto11 = () => {
     if (window.innerWidth <= 615) {
          setPhoto11(false);
           return;
         }
         setPhoto11(isPhoto11 => !isPhoto11);
    }
    const fullPhoto12 = () => {
     if (window.innerWidth <= 615) {
          setPhoto12(false);
          return;
         }
         setPhoto12(isPhoto12 => !isPhoto12);
    }
    const fullPhoto13 = () => {
     if (window.innerWidth <= 615) {
          setPhoto13(false);
          return;
         }
         setPhoto13(isPhoto13 => !isPhoto13);
    }
    const fullPhoto14 = () => {
     if (window.innerWidth <= 615) {
          setPhoto14(false);
          return;
         }
         setPhoto14(isPhoto14 => !isPhoto14);
    }
    const fullPhoto15 = () => {
     if (window.innerWidth <= 615) {
          setPhoto15(false);
          return;
         }
         setPhoto15(isPhoto15 => !isPhoto15);
    }
    const fullPhoto16 = () => {
     if (window.innerWidth <= 615) {
          setPhoto16(false);
          return;
         }
         setPhoto16(isPhoto16 => !isPhoto16);
    }
   
    return (
        <>
        <div className="containerPhoto" >
        <h2 className="galaryh2">Nasze prace</h2>
        <div className="allphoto" >
            <div className="onerow">
                <img onClick={fullPhoto1} className={isPhoto1 ? 'changephoto' :'allphoto'} src={Foto1} alt="foto1" />
                <img onClick={fullPhoto2} className={isPhoto2 ? 'changephoto' :'allphoto'} src={Foto2} alt="foto2" />
                <img onClick={fullPhoto3} className={isPhoto3 ? 'changephoto' :'allphoto'} src={Foto3} alt="foto3" />
                <img onClick={fullPhoto10} className={isPhoto10 ? 'changephoto' :'allphoto'} src={Foto10} alt="foto10" />
          </div>
            <div className="tworow">
                    <img onClick={fullPhoto5} className={isPhoto5 ? 'changephoto' :'allphoto'} src={Foto5} alt="foto5" />
                    <img onClick={fullPhoto6} className={isPhoto6 ? 'changephoto' :'allphoto'} src={Foto6} alt="foto6" />
                    <img onClick={fullPhoto7} className={isPhoto7 ? 'changephoto' :'allphoto hiddenrow'} src={Foto7} alt="foto7" />
                    <img onClick={fullPhoto8} className={isPhoto8 ? 'changephoto' :'allphoto hiddenrow'} src={Foto8} alt="foto8" />
            </div>
            <div className="threerow">
                    <img onClick={fullPhoto9} className={isPhoto9 ? 'changephoto' :'allphoto'} src={Foto9} alt="foto9" />
                    <img onClick={fullPhoto4} className={isPhoto4 ? 'changephoto' :'allphoto'} src={Foto4} alt="foto4" />
                    <img onClick={fullPhoto11} className={isPhoto11 ? 'changephoto' :'allphoto'} src={Foto11} alt="foto11" />
                    <img onClick={fullPhoto12} className={isPhoto12 ? 'changephoto' :'allphoto'} src={Foto12} alt="foto12" />
            </div>
            <div className="fourrow">
                    <img onClick={fullPhoto13} className={isPhoto13 ? 'changephoto' :'allphoto'} src={Foto13} alt="foto13" />
                    <img onClick={fullPhoto14} className={isPhoto14 ? 'changephoto' :'allphoto'} src={Foto14} alt="foto14" />
                    <img onClick={fullPhoto15} className={isPhoto15 ? 'changephoto' :'allphoto'} src={Foto15} alt="foto15" />
                    <img onClick={fullPhoto16} className={isPhoto16 ? 'changephoto' :'allphoto'} src={Foto16} alt="foto16" />
            </div>
        </div>
    </div>
        </>
    );
}

function Gallary() {
    return (
     <div className="body_wrapper">
        <FirstScreen />
        <Lang />
        <Imgphoto />
        <FiveSCreen />
        </div>
       
    );
}
export default Gallary;