import FiveScreen from '../../FiveScreen/FiveScreen';
import window from './../../img/HopClean/articel10.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import FirstScreen from "../../FirstScreen/ContainerOne";
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';


function Lang () {
    const [isLang, setLang] = useState(false);
    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>Pl</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/en/articel10'>En</Link> :<Link to='/articel10'>Pl</Link>} </button>
        </div>
        </>
    )
}

function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Pranie i czyszczenie mebli tapicerowanych na jachtach i łodziach </h2>
            <p>
            Pranie i czyszczenie mebli tapicerowanych na jachtach i łodziach w Krakowie – ożyw swoje wnętrza!
            </p>
            <p>
            Marzy Ci się nowoczesny, elegancki i pachnący jacht czy łódź? A może marzyłeś o wymarzonym rejsie na wodach krystalicznie czystych jezior czy morza? Aby twój jacht czy łódź zawsze wyglądały jak nowe, zadbaj o ich wnętrza! Oferujemy profesjonalne pranie i czyszczenie mebli tapicerowanych na jachtach i łodziach w Krakowie, abyś mógł cieszyć się komfortem i luksusem na swoim pokładzie.
            </p>
            <img className="window" src={window} title='window' alt="window"/>
            <p>
            Nasi wykwalifikowani specjaliści posiadają bogate doświadczenie w czyszczeniu i praniu mebli tapicerowanych na jachtach i łodziach. Dzięki zastosowaniu najnowszych technik i profesjonalnych środków czyszczących, przywrócimy Twoim meblom ich pierwotny blask. Usuniemy plamy, zabrudzenia, kurz i nieprzyjemne zapachy, aby Twoje meble tapicerowane prezentowały się jak nowe.
            </p>
            <p>Pranie i czyszczenie mebli tapicerowanych na jachtach i łodziach w Krakowie to nie tylko kwestia estetyki, ale także higieny. Wnętrza jachtów i łodzi są narażone na działanie wilgoci, brudu i bakterii, co może prowadzić do powstawania pleśni i innych niekorzystnych warunków dla zdrowia. Dlatego warto zadbać o regularne pranie i czyszczenie mebli tapicerowanych, aby utrzymać czystość i świeżość na pokładzie.</p>
            <p>Dlaczego warto skorzystać z naszych usług? Oto kilka powodów:</p>
            <ul className="windowraz">
                <li>Profesjonalizm – Nasz zespół składa się z doświadczonych specjalistów, którzy posiadają wiedzę i umiejętności w zakresie prania i czyszczenia mebli tapicerowanych na jachtach i łodziach.</li>
                <li>
                Wyjątkowe podejście – Każde zlecenie traktujemy indywidualnie, dopasowując metody i środki czyszczące do rodzaju mebli tapicerowanych oraz stopnia zabrudzenia.
                </li>
                <li>
                Bezpieczeństwo – Stosujemy profesjonalne środki czyszczące, które są skuteczne w usuwaniu brudu, ale jednocześnie bezpieczne dla zdrowia ludzi i zwierząt.
                </li>
                <li>Wygoda – Działamy szybko i sprawnie, dostosowując się do Twojego harmonogramu i potrzeb. Możemy przeprowadzić pranie i czyszczenie mebli tapicerowanych na miejscu, na Twoim jachcie czy łodzi, bez konieczności ich demontażu.</li>
                <li>Efektywność – Dzięki naszym profesjonalnym narzędziom i technikom, osiągamy doskonałe rezultaty w usuwaniu plam, brudu i nieprzyjemnych zapachów z mebli tapicerowanych na jachtach i łodziach, pozostawiając je świeże, czyste i przyjemne w dotyku.</li>
                <li>
                Ochrona inwestycji – Jachty i łodzie są często kosztownymi inwestycjami, dlatego warto dbać o ich wyposażenie, w tym meble tapicerowane. Regularne pranie i czyszczenie mebli tapicerowanych pozwala na zachowanie ich jakości, wydłuża ich żywotność i chroni przed przedwczesnym zużyciem.
                </li>
                <li>Profesjonalne doradztwo – Nasz zespół chętnie doradzi Ci w zakresie pielęgnacji mebli tapicerowanych na jachtach i łodziach. Doradzimy, jak dbać o meble, aby utrzymać je w najlepszym stanie, oraz polecimy odpowiednie środki czyszczące do pielęgnacji mebli tapicerowanych, które pozwolą Ci cieszyć się nimi przez dłuższy czas.</li>
                <li>Konkurencyjne ceny – Oferujemy konkurencyjne ceny za nasze usługi prania i czyszczenia mebli tapicerowanych na jachtach i łodziach w Krakowie. Warto zainwestować w profesjonalne czyszczenie, które pozwoli Ci zachować luksusowy wygląd i komfort wnętrza Twojego jachtu czy łodzi.</li>
            </ul>
            <p>
            Nie czekaj! Zadbaj o czystość i wygląd mebli tapicerowanych na swoim jachcie czy łodzi w Krakowie już dziś. Skorzystaj z naszych profesjonalnych usług prania i czyszczenia mebli tapicerowanych i ciesz się świeżością, komfortem i luksusem na swoim pokładzie! Skontaktuj się z nami teraz, aby umówić się na bezpłatną wycenę i skorzystać z naszych usług.
            </p>
        
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <FirstScreen />
        <Lang />
        <Furnitura />
        <FiveScreen />
        </div>
    );
}
export default ArticelTwo;