import React from 'react';
import './index.css';
import './App.css';
import FirstScreen from './components/FirstScreen/FirstScreen';
import SecondScreen from './components/SecondSreen/SecondScreen';
import ThirdScreen from './components/ThirdScreen/ThirdScreen';
import FourScreen from './components/FourScreen/FourScreen';
import FiveScreen from './components/FiveScreen/FiveScreen';
import { Routes, Route, Navigate } from 'react-router-dom';
import Services from './components/Pages/Services';
import Whoops404 from './components/Pages/Whoops404';
import Gallary from './components/Pages/Gallary';
import Contacts from './components/Pages/Contacts';
import ArticelOne from './components/Pages/Articels/ArticelOne';
import ArticelTwo from './components/Pages/Articels/ArticelTwo';
import ArticelThree from './components/Pages/Articels/ArticelThree';
import ArticelFour from './components/Pages/Articels/ArticelFour';
import ArticelFive from './components/Pages/Articels/ArticelFive';
import ArticelSix from './components/Pages/Articels/ArticelSix';
import ArticelSeven from './components/Pages/Articels/ArticelSeven';
import Articel8 from './components/Pages/Articels/Articel8';
import Articel9 from './components/Pages/Articels/Articel9';
import Articel10 from './components/Pages/Articels/Articel10';
import Articel11 from './components/Pages/Articels/Articel11';
import Articel12 from './components/Pages/Articels/Articel12';
import Articel13 from './components/Pages/Articels/Articel13';
import Articel14 from './components/Pages/Articels/Articel14';
import Articel15 from './components/Pages/Articels/Articel15';
import Articel16 from './components/Pages/Articels/Articel16';
import Articel17 from './components/Pages/Articels/Articel17';
import Articel18 from './components/Pages/Articels/Articel18';
import Articel19 from './components/Pages/Articels/Articel19';
import Articel20 from './components/Pages/Articels/Articel20';
import Articel21 from './components/Pages/Articels/Articel21';
import Articel22 from './components/Pages/Articels/Articel22';
import Articel23 from './components/Pages/Articels/Articel23';
import Articel24 from './components/Pages/Articels/Articel24';
import Onas from './components/Pages/Onas';
import FirstScreenEn from './LangEn/FirstScreenEn/FirstScreen';
import SecondScreenEn from './LangEn/SecondSreenEn/SecondScreen';
import ThirdScreenEn from './LangEn/ThirdScreenEn/ThirdScreen';
import FourScreenEn from './LangEn/FourScreenEn/FourScreen';
import FiveScreenEn from './LangEn/FiveScreenEn/FiveScreen';
import OnasEn from '../src/LangEn/Pages/Onas';
import ServicesEn from '../src/LangEn/Pages/Services';
import GallaryEn from '../src/LangEn/Pages/Gallary';
import ContactsEn from '../src/LangEn/Pages/Contacts';
import ArticelOneEn from '../src/LangEn/Pages/Articels/ArticelOne';
import ArticelTwoEn from '../src/LangEn/Pages/Articels/ArticelTwo';
import ArticelThreeEn from '../src/LangEn/Pages/Articels/ArticelThree';
import ArticelFourEn from '../src/LangEn/Pages/Articels/ArticelFour';
import ArticelFiveEn from '../src/LangEn/Pages/Articels/ArticelFive';
import ArticelSixEn from '../src/LangEn/Pages/Articels/ArticelSix';
import ArticelSevenEn from '../src/LangEn/Pages/Articels/ArticelSeven';
import Articel8En from '../src/LangEn/Pages/Articels/Articel8';
import Articel9En from '../src/LangEn/Pages/Articels/Articel9';
import Articel10En from '../src/LangEn/Pages/Articels/Articel10';
import Articel11En from '../src/LangEn/Pages/Articels/Articel11';
import Articel12En from '../src/LangEn/Pages/Articels/Articel12';
import Articel13En from '../src/LangEn/Pages/Articels/Articel13';
import Articel14En from '../src/LangEn/Pages/Articels/Articel14';
import Articel15En from '../src/LangEn/Pages/Articels/Articel15';
import Articel16En from '../src/LangEn/Pages/Articels/Articel16';
import Articel17En from '../src/LangEn/Pages/Articels/Articel17';
import Articel18En from '../src/LangEn/Pages/Articels/Articel18';
import Articel19En from '../src/LangEn/Pages/Articels/Articel19';
import Articel20En from '../src/LangEn/Pages/Articels/Articel20';
import Articel21En from '../src/LangEn/Pages/Articels/Articel21';
import Articel22En from '../src/LangEn/Pages/Articels/Articel22';
import Articel23En from '../src/LangEn/Pages/Articels/Articel23';
import Reviews from './components/Reviews/Reviews';
import ReviewsEn from './LangEn/Reviews/Reviews';
import SwiperVideoGallery from './components/SwiperVideoGallery/SwiperVideoGallery';

export function Main() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <SecondScreen />
      <ThirdScreen />
      <SwiperVideoGallery />
      <Reviews />
      <FourScreen />
      <FiveScreen />
    </div>
  );
}
export function MainEn() {
  return (
    <div className="body_wrapper">
      <FirstScreenEn />
      <SecondScreenEn />
      <ThirdScreenEn />
      <ReviewsEn />
      <FourScreenEn />
      <FiveScreenEn />
    </div>
  );
}

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/services" element={<Services />} />
        <Route path="/elementor-548" element={<Navigate to="/" />} />
        <Route path="/usuwanie-plam" element={<Navigate to="/" />} />
        <Route
          path="/pranie-wykladzin-biurowych"
          element={<Navigate to="/" />}
        />
        <Route
          path="/profesjonalne-czyszczenie-tapicerki-na-sucho"
          element={<Navigate to="/" />}
        />
        <Route
          path="/czyszczenie-dzieciecego-fotelika-samochodowego"
          element={<Navigate to="/" />}
        />
        <Route
          path="/pranie-kabiny-ciezarowki-wroclaw"
          element={<Navigate to="/" />}
        />
        <Route
          path="/uslugi-dronem-we-wroclawiu"
          element={<Navigate to="/" />}
        />
        <Route
          path="/?elementor_library=uslugi-dronem-we-wroclawiu-48-506-77-86-84"
          element={<Navigate to="/" />}
        />
        <Route path="/pranie-drapaka-dla-kota" element={<Navigate to="/" />} />
        <Route
          path="/pranie-samochodow-wroclaw-i-okolice-6-22-7-u-klienta"
          element={<Navigate to="/" />}
        />
        <Route
          path="/do-prania-czyszczenia-uzywamy-tylko-profesjonalnych-odpowiednich-srodkow-bezpiecznych"
          element={<Navigate to="/" />}
        />
        <Route
          path="/pranie-mebli-pranie-tapicerki-wroclaw-centrum-i-okolice"
          element={<Navigate to="/" />}
        />
        <Route
          path="/elementor_library=uslugi-dronem-we-wroclawiu-48-506-77-86-84"
          element={<Navigate to="/" />}
        />
        <Route path="/pranie-chemiczne-dywanow" element={<Navigate to="/" />} />
        <Route
          path="/pranie-wozkow-dzieciecych-i-fotelikow"
          element={<Navigate to="/articel12" />}
        />
        <Route
          path="/czyszczenie-wnetrza-samochodu-wro"
          element={<Navigate to="/articel11" />}
        />
        <Route
          path="/czyszczenie-tapicerki-skorzanej-wro"
          element={<Navigate to="/" />}
        />
        <Route path="/page/2" element={<Navigate to="/" />} />
        <Route path="/pranie-materaca-wro" element={<ArticelTwo />} />
        <Route
          path="/pranie-wykladzin-biurowych"
          element={<Navigate to="/" />}
        />
        <Route path="/o-nas" element={<Onas />} />
        <Route path="/cennik" element={<Services />} />
        <Route path="/galiria" element={<Gallary />} />
        <Route path="/kontakt" element={<Contacts />} />
        <Route path="/articelone" element={<ArticelOne />} />
        <Route path="/articeltwo" element={<ArticelTwo />} />
        <Route path="/articelthree" element={<ArticelThree />} />
        <Route path="/articelfour" element={<ArticelFour />} />
        <Route path="/articelfive" element={<ArticelFive />} />
        <Route path="/articelsix" element={<ArticelSix />} />
        <Route path="/articelseven" element={<ArticelSeven />} />
        <Route path="/articel8" element={<Articel8 />} />
        <Route path="/articel9" element={<Articel9 />} />
        <Route path="/articel10" element={<Articel10 />} />
        <Route path="/articel11" element={<Articel11 />} />
        <Route path="/articel12" element={<Articel12 />} />
        <Route path="/articel13" element={<Articel13 />} />
        <Route path="/articel14" element={<Articel14 />} />
        <Route path="/articel15" element={<Articel15 />} />
        <Route path="/articel16" element={<Articel16 />} />
        <Route path="/articel17" element={<Articel17 />} />
        <Route path="/articel18" element={<Articel18 />} />
        <Route path="/articel19" element={<Articel19 />} />
        <Route path="/articel20" element={<Articel20 />} />
        <Route path="/articel21" element={<Articel21 />} />
        <Route path="/articel22" element={<Articel22 />} />
        <Route path="/articel23" element={<Articel23 />} />
        <Route path="/articel24" element={<Articel24 />} />
        <Route path="*" element={<Whoops404 />} />
        <Route path="/en" element={<MainEn />} />
        <Route path="/en/o-nas" element={<OnasEn />} />
        <Route path="/en/cennik" element={<ServicesEn />} />
        <Route path="/en/galiria" element={<GallaryEn />} />
        <Route path="/en/kontakt" element={<ContactsEn />} />
        <Route path="/en/articelone" element={<ArticelOneEn />} />
        <Route path="/en/articeltwo" element={<ArticelTwoEn />} />
        <Route path="/en/articelthree" element={<ArticelThreeEn />} />
        <Route path="/en/articelfour" element={<ArticelFourEn />} />
        <Route path="/en/articelfive" element={<ArticelFiveEn />} />
        <Route path="/en/articelsix" element={<ArticelSixEn />} />
        <Route path="/en/articelseven" element={<ArticelSevenEn />} />
        <Route path="/en/articel8" element={<Articel8En />} />
        <Route path="/en/articel9" element={<Articel9En />} />
        <Route path="/en/articel10" element={<Articel10En />} />
        <Route path="/en/articel11" element={<Articel11En />} />
        <Route path="/en/articel12" element={<Articel12En />} />
        <Route path="/en/articel13" element={<Articel13En />} />
        <Route path="/en/articel14" element={<Articel14En />} />
        <Route path="/en/articel15" element={<Articel15En />} />
        <Route path="/en/articel16" element={<Articel16En />} />
        <Route path="/en/articel17" element={<Articel17En />} />
        <Route path="/en/articel18" element={<Articel18En />} />
        <Route path="/en/articel19" element={<Articel19En />} />
        <Route path="/en/articel20" element={<Articel20En />} />
        <Route path="/en/articel21" element={<Articel21En />} />
        <Route path="/en/articel22" element={<Articel22En />} />
        <Route path="/en/articel23" element={<Articel23En />} />
      </Routes>
    </>
  );
}

export default App;
