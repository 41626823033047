import React from 'react';
import ImgLogo from '../img/HopClean/hofclean.png';
import Korher from '../img/HopClean/korher.jpg';
import './HeaderLogo.css';
import { Link } from 'react-router-dom';

function Logo() {
  return (
    <>
      <div className="logo" id="logo">
        <Link to="/">
          <img src={ImgLogo} alt="logo" />
        </Link>
        <img src={Korher} alt="karcher" className="karcher" />
      </div>
    </>
  );
}

export default Logo;
