import React, { useState } from 'react';
import FirstScreen from '../FirstScreen/ContainerOne';
import FiveSCreen from '../FiveScreen/FiveScreen';
import '../Pages/Services.css';

import '../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>Pl</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/en/cennik">En</Link>
          ) : (
            <Link to="/cennik">Pl</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

export function Price() {
  return (
    <>
      <div className="price">
        <h2 className="other">Cennik</h2>
        <div className="outsidewidthtable">
          <p className="lastquestion">
            {' '}
            <span className="attantion">
              Ceny podane poniżej mają charakter orientacyjny. Cena uzależniona
              jest od stopnia zabrudzenia oraz ilości zamówienia.{' '}
            </span>
            <span className="attantion">
              Ostateczną cenę można poznać dzwoniąc do nas lub wysyłając zdjęcie
              tego co wymaga czyszczenia, a nasz pracownik poda dokładną wycenę.
            </span>
            {/* <span className="attantion attantionbold">
              {' '}
              Minimalna kwota zamówienia 250 zł
            </span> */}
          </p>
          <table className="table">
            <tbody>
              <tr>
                <th className="thleft">Nazwa usług</th>
                <th>Koszt (zl)</th>
              </tr>
              <tr>
                <td colSpan="2" className="priceOne">
                  CENNIK PRANIA TAPICEREK MEBLOWYCH:
                </td>
              </tr>
              <tr>
                <td>Krzesło</td>
                <td className="center"> od 10 do 30</td>
              </tr>
              <tr>
                <td>Pufa</td>
                <td className="center"> od 20 do 50</td>
              </tr>
              <tr>
                <td>Fotel</td>
                <td className="center">od 20 do 80</td>
              </tr>
              <tr>
                <td>Wersalka</td>
                <td className="center">od 80 do 250</td>
              </tr>
              <tr>
                <td>Narożnik</td>
                <td className="center">od 100 do 400</td>
              </tr>
              <tr>
                <td> Materacy </td>
                <td className="center"> od 100 do 400</td>
              </tr>
              <tr>
                <td>Pranie pluszaków</td>
                <td className="center"> od 10 do 200</td>
              </tr>
              <tr>
                <td>Pranie gondoli wózków dziecięcych</td>
                <td className="center"> od 50 do 200</td>
              </tr>
              <tr>
                <td>Pranie fotelików samochodowych dla dzieci</td>
                <td className="center">od 50 do 200</td>
              </tr>

              <tr>
                <td colSpan="2" className="priceOne">
                  CENNIK PRANIA WYKŁADZIN DYWANÓW:
                </td>
              </tr>

              <tr>
                <td>Dywan</td>
                <td className="center"> od 10 do 50 zł/m2</td>
              </tr>

              <tr>
                <td>Wykładziny</td>
                <td className="center"> od 2 do 30 zł/m2</td>
              </tr>
              <tr>
                <td colSpan="2" className="priceOne">
                  Czyszczenie w samochodzie:
                </td>
              </tr>
              <tr>
                <td>Pranie podstawowe tapicerki (przednie fotele)</td>
                <td className="center"> od 50 do 100</td>
              </tr>
              <tr>
                <td>Pranie zaawansowane (fotele, kanapa)</td>
                <td className="center"> od 150 do 200</td>
              </tr>
              <tr>
                <td>
                  Pranie expert (fotele, kanapa, boczki, wykładzina, podsufitka,
                  bagażnik)
                </td>
                <td className="center"> od 400 do 1000</td>
              </tr>
            </tbody>
          </table>

          <p className="lastquestion">
            {' '}
            <span>Zapraszamy do skorzystania z naszych usług!</span>
          </p>
        </div>
      </div>
    </>
  );
}

function Services() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <Lang />
      <Price />
      <FiveSCreen />
    </div>
  );
}

export default Services;
