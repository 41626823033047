// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Reviews.css';
import foto1 from '../../components/img/HopClean/reviews/Maksym_Dovhenko.jpg';
import foto2 from '../../components/img/HopClean/reviews/Justyna_Cofala.jpg';
import foto3 from '../../components/img/HopClean/reviews/Marta Ozga.jpg';
import foto4 from '../../components/img/HopClean/reviews/Oleksandr_Stanislavov.jpg';
import foto5 from '../../components/img/HopClean/reviews/Vitalii_Holubiatykov.jpg';
import foto6 from '../../components/img/HopClean/reviews/Kateryna.png';
import foto7 from '../../components/img/HopClean/reviews/Anna_Wierbic.png';

// import required modules
import { EffectFlip, Pagination, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
function ReviewOne() {
  return (
    <div className="review">
      <img src={foto1} alt="Maksym_Dovhenko" className="review__img" />
      <p className="review__text">
        I am satisfied with the service, they work quickly and punctually, you
        can always agree on prices and it is pleasant to deal with them. I
        recommend.
      </p>

      <Link
        to="https://www.facebook.com/HOFCLEAN/reviews"
        target="_blank"
        alt="review-link"
        className="review__link"
      >
        <span>More details...</span>
      </Link>
    </div>
  );
}
function Review2() {
  return (
    <div className="review">
      <img src={foto2} alt="Maksym_Dovhenko" className="review__img" />
      <p className="review__text">
        I highly recommend it. Fast, accurate, very nice people 😊
      </p>

      <Link
        to="https://www.facebook.com/HOFCLEAN/reviews"
        target="_blank"
        alt="review-link"
        className="review__link"
      >
        <span>More details...</span>
      </Link>
    </div>
  );
}
function Review3() {
  return (
    <div className="review">
      <img src={foto3} alt="Maksym_Dovhenko" className="review__img" />
      <p className="review__text">
        The order to wash the mattress was accepted immediately, I called in the
        morning and it was already clean in the afternoon. The lady with whom I
        dealt with the matter was very nice, I recommend...
      </p>

      <Link
        to="https://www.facebook.com/HOFCLEAN/reviews"
        target="_blank"
        alt="review-link"
        className="review__link"
      >
        <span>More details...</span>
      </Link>
    </div>
  );
}
function Review4() {
  return (
    <div className="review">
      <img src={foto4} alt="Maksym_Dovhenko" className="review__img" />
      <p className="review__text">Highly qualified specialists, I recommend.</p>

      <Link
        to="https://www.facebook.com/HOFCLEAN/reviews"
        target="_blank"
        alt="review-link"
        className="review__link"
      >
        <span>More details...</span>
      </Link>
    </div>
  );
}
function Review5() {
  return (
    <div className="review">
      <img src={foto5} alt="Maksym_Dovhenko" className="review__img" />
      <p className="review__text">
        Because it's really fast and qualitatively. I recommend it!
      </p>

      <Link
        to="https://www.facebook.com/HOFCLEAN/reviews"
        target="_blank"
        alt="review-link"
        className="review__link"
      >
        <span>More details...</span>
      </Link>
    </div>
  );
}
function Review6() {
  return (
    <div className="review">
      <img src={foto6} alt="Maksym_Dovhenko" className="review__img kateryna" />
      <p className="review__text">
        I recommend quick and professional dry cleaning of the sofa. I highly
        recommend Mr. Witek👍 I recommend, full professionalism!
      </p>

      <Link
        to="https://maps.app.goo.gl/zLE2btcaZi2Tjzo89"
        target="_blank"
        alt="review-link"
        className="review__link kateryna-link"
      >
        <span>More details...</span>
      </Link>
    </div>
  );
}
function Review7() {
  return (
    <div className="review">
      <img src={foto7} alt="Maksym_Dovhenko" className="review__img kateryna" />
      <p className="review__text">
        The service was carried out efficiently and carefully. I highly
        recommend it. The furniture hasn't been this clean for a long time. It's
        immediately nicer to use. Photos the next day after drying.
      </p>

      <Link
        to="https://maps.app.goo.gl/1eCSBS6ZohiYt5HS8"
        target="_blank"
        alt="review-link"
        className="review__link kateryna-link"
      >
        <span>More details...</span>
      </Link>
    </div>
  );
}

function SwiperReviews() {
  return (
    <>
      <Swiper
        loop={true}
        effect={'flip'}
        grabCursor={true}
        pagination={true}
        navigation={true}
        modules={[EffectFlip, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <ReviewOne />
        </SwiperSlide>
        <SwiperSlide>
          <Review2 />
        </SwiperSlide>
        <SwiperSlide>
          <Review3 />
        </SwiperSlide>
        <SwiperSlide>
          <Review4 />
        </SwiperSlide>
        <SwiperSlide>
          <Review5 />
        </SwiperSlide>
        <SwiperSlide>
          <Review6 />
        </SwiperSlide>
        <SwiperSlide>
          <Review7 />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
export default SwiperReviews;
