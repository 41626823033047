import React, { useEffect, useState } from 'react';
import './LinkQuick.css';
import { Link } from 'react-router-dom';

function LinkQuick() {
  const [isScrollUp, setScrollUp] = useState(true);

  useEffect(() => {
    if (isScrollUp) {
      window.scroll({ top: 0 });
      setScrollUp(false);
    }
  }, [isScrollUp]);

  return (
    <div className="linkQuick">
      <ul>
        <li>
          <Link to="/en">Main</Link>
        </li>
        <li>
          <Link to="/en/o-nas">About Us</Link>
        </li>
        <li>
          <Link to="/en/cennik">Price</Link>
        </li>
        <li>
          <Link to="/en/galiria">Gallery</Link>
        </li>
        <li>
          <Link to="/en/kontakt">Contact</Link>
        </li>
        <li>
          <Link to="/en/articelone">Blog</Link>
        </li>
      </ul>
    </div>
  );
}

export default LinkQuick;
