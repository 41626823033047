import FiveScreen from '../../FiveScreen/FiveScreen';
import window from './../../img/HopClean/photo13.jpg';
import window2 from '../../img/HopClean/photo13_2.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import FirstScreen from "../../FirstScreen/ContainerOne";
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';


function Lang () {
    const [isLang, setLang] = useState(false);
    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>Pl</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/en/articel13'>En</Link> :<Link to='/articel13'>Pl</Link>} </button>
        </div>
        </>
    )
}
function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Czym wyczyścić sofy i krzesła tapicerowane? </h2>
            <p>
            Do czyszczenia sof i krzeseł tapicerowanych można użyć kilku różnych metod, w zależności od rodzaju plam, materiału tapicerki i dostępnych środków czyszczących. Oto kilka ogólnych wskazówek, które mogą Ci pomóc:
            </p>
            <p>
            Odkurzanie: Rozpocznij od odkurzenia mebli tapicerowanych, aby usunąć drobne zanieczyszczenia, kurz i sierść zwierząt. Użyj szczotki z miękkim zakończeniem lub specjalnej nasadki do odkurzacza przeznaczonej do czyszczenia tapicerki.
            </p>
            <img className="window" src={window} title='window' alt="window"/>
            <p>
            Spryskiwanie środka czyszczącego: Przed przystąpieniem do czyszczenia całej powierzchni tapicerki, przetestuj wybrany środek czyszczący na niewidocznym obszarze, aby upewnić się, że nie spowoduje on plam lub uszkodzeń. Możesz również sprawdzić zalecenia producenta mebli dotyczące czyszczenia.
            </p>
            <p>
            Czyszczenie plam: Jeśli na tapicerce znajdują się konkretne plamy, należy je najpierw zlokalizować. Można zastosować różne środki czyszczące w zależności od rodzaju plamy. Na przykład:
            </p>
           <p> 
           Plamy z napojów: Na świeże plamy z kawy, herbaty, soku lub czerwonego wina możesz spróbować nałożyć na nie trochę chłodnej wody z delikatnym detergentem i delikatnie je wytrzeć. Następnie przetrzyj wilgotną szmatką, aby usunąć resztki detergentu i nie pozostawić plam.
           </p>
          
            <img className="window" src={window2} title='window' alt="window"/>
           
            <p>Plamy tłuszczu: Na plamy z tłuszczu, takie jak plamy z jedzenia lub oleju, można spróbować nałożyć na nie proszek do prania lub specjalny środek czyszczący do plam tłuszczowych. Pozostaw go na chwilę, a następnie delikatnie wytrzyj lub odkurz.</p>
            <p>Plamy z atramentu lub barwników: Plamy z atramentu lub barwników można próbować usuwać za pomocą specjalnych środków czyszczących do plam z atramentu lub barwników, które są dostępne w sklepach.</p>
            <p>Czyszczenie całej powierzchni: Po usunięciu plam możesz przystąpić do czyszczenia całej powierzchni mebli. Nałóż niewielką ilość odpowiedniego środka czyszczącego na gąbkę lub miękką szczoteczkę i delikatnie wmasuj go w tapicerkę, wykonując ruchy okrężne. Następnie usuń nadmiar środka czyszczącego za pomocą czystej wilgotnej szmatki.</p>
            <p>Suszenie: Po czyszczeniu powierzchni tapicerki ważne jest, aby meble dobrze wysuszyć. Można użyć wentylatora lub klimatyzatora, aby przyspieszyć proces suszenia. Unikaj używania mebli, dopóki nie wyschną w pełni.</p>
            <p>
            Pamiętaj, że powyższe wskazówki to ogólne zalecenia, a skuteczność czyszczenia zależy od wielu czynników, takich jak rodzaj tapicerki i rodzaj plam. Jeśli nie jesteś pewien, jak należy czyścić konkretne meble tapicerowane, zawsze warto skonsultować się z producentem lub specjalistą ds. czyszczenia tapicerki.
            </p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <FirstScreen />
        <Lang />
        <Furnitura />
        <FiveScreen />
        </div>
    );
}
export default ArticelTwo;