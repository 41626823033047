import window from './../../img/HopClean/articel8.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";
import React, {useState} from "react";
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';


function Lang () {
    const [isLang, setLang] = useState(false);
    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>En</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/articel8'>Pl</Link> :<Link to='/en/articel8'>En</Link>} </button>
        </div>
        </>
    )
}

function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>How often should the carpet in the office be cleaned </h2>
            <p>
            Carpet is a popular choice for offices due to its functionality and aesthetics. However, to keep it clean and durable, it needs to be cleaned regularly. In this article, we'll discuss how often you should clean your office carpet and why it's important.
            </p>
            <p>
            First of all, it is worth remembering that the carpet is susceptible to the accumulation of dust, dirt, mites and other allergens. These substances can affect office air quality and lead to allergies and other health problems. That's why it's so important to clean your carpet regularly.
            </p>
            <img className="window" src={window} title='window' alt="window"/>
            <p>
            In general, it is recommended that the office carpet be vacuumed at least once a week. However, if your office gets a lot of traffic and your rug is heavily used, you may need to vacuum more often. In this case, it is recommended to vacuum the carpet at least three times a week.
            </p>
            <p>
            As for deeper cleaning, it is recommended to do it at least once a year. In the case of heavily soiled carpet, it is recommended to use the services of professional cleaning companies. Professional cleaning will remove more difficult to remove dirt and refresh the carpet.
            </p>
            <p>There are many methods to clean your carpet including vacuuming, dry cleaning, wet cleaning and steam cleaning. Choosing the right method depends on the type of carpet, the degree of soiling, and your office preferences and budget. For deeper cleaning, it is best to use the services of professional cleaning companies that have the right tools and cleaning agents.</p>
            <p>It is also important to regularly remove stains from the carpet. In the case of larger stains, it is recommended to remove them as soon as possible to prevent more difficult stains and dirt. To remove the stains, you can use special cleaners or home remedies, such as the use of soapy water or a vinegar solution.</p>
            <p>In conclusion, your office carpet needs to be vacuumed regularly to keep it clean and durable, and to prevent the accumulation of dust, dirt and allergens. It is recommended to vacuum the carpet at least once a week, but if the carpet is heavily used, it may need to be vacuumed more often. </p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <FirstScreenEn />
        <Lang />
        <Furnitura />
        <FiveSCreenEn />
        </div>
    );
}
export default ArticelTwo;