import window from './../../img/HopClean/articel11.jpg';
import window2 from '../../img/HopClean/articel112.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";
import React, {useState} from "react";
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';


function Lang () {
    const [isLang, setLang] = useState(false);
    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>En</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/articel11'>Pl</Link> :<Link to='/en/articel11'>En</Link>} </button>
        </div>
        </>
    )
}


function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Washing car upholstery </h2>
            <p>
            Car upholstery is one of the parts of the vehicle that gets dirty the fastest. Daily use, contact with the human body and contamination from the outside cause the upholstery to quickly lose its original appearance and color. That is why car upholstery cleaning is so important and should be carried out regularly. In this article, we present several reasons why car upholstery cleaning is so important.
            </p>
            <p>
            First of all, regular washing of car upholstery affects the hygiene and health of the driver and passengers. Car upholstery can collect various types of contaminants, such as dust, bacteria, viruses, molds or mites, which can affect our health and well-being. Therefore, it is important to carry out regular laundering to remove these impurities.
            </p>
            <img className="window" src={window} title='window' alt="window"/>
            <p>
            Secondly, washing car upholstery affects the aesthetics and functionality of the car. Clean and fragrant upholstery makes the car look more aesthetic and more pleasant to stay in. In addition, dirt on the upholstery can affect the durability and functionality of the material, which in the long run may lead to the need to replace the upholstery.
            </p>
            <p>
            Thirdly, washing car upholstery allows you to remove difficult stains and dirt. Car upholstery is exposed to various types of stains, such as coffee, tea, juice or oil stains. These stains can be difficult to remove with ordinary cleaning products, so it is worth using a professional laundry that will allow even the toughest stains to be thoroughly removed.
            </p>
           <p>Fourthly, washing car upholstery allows you to remove unpleasant odors. The car is exposed to all kinds of odors, such as cigarettes, food or moisture. These odors can be difficult to remove, so it's a good idea to use a professional wash to remove unpleasant odors from your car upholstery.</p>
            <p>In conclusion, car upholstery cleaning is extremely important and should be carried out regularly. Thanks to regular washing, we will provide ourselves and our passengers with a clean and safe environment in the car, we will maintain the aesthetics and functionality of the upholstery.</p>
            <img className="window" src={window2} title='window' alt="window"/>
            <p>The cab of a truck is a place where drivers spend many hours a day. Therefore, it is important that the cabin is clean and pleasant to be in. One of the best ways to keep your truck cabin clean and hygienic is regular washing. In this article, we present a few reasons why truck cabin cleaning is so important. </p>
            <p>Firstly, regular washing of the truck cabin affects the hygiene and health of the driver. The cab of a truck can accumulate various types of contaminants, such as dust, bacteria, viruses, molds or mites, which can affect our health and well-being. Therefore, it is important to carry out regular laundering to remove these impurities.</p>
            <p>Secondly, washing the truck cabin affects the aesthetics and functionality of the vehicle. A clean cabin makes the truck look more aesthetic and more pleasant to be in. In addition, contamination in the cabin can affect the durability and functionality of the material, which in the long term may lead to the need to replace the cabin.</p>
            <p>Thirdly, washing the truck cabin allows you to remove tough stains and dirt. Truck drivers often travel in harsh conditions, which means that the cabin is exposed to all kinds of stains, such as oil, grease and other substances. These stains can be difficult to remove with ordinary cleaning products, so it is worth using a professional laundry that will allow even the toughest stains to be thoroughly removed.</p>
            <p>Fourthly, washing the truck cabin allows you to remove unpleasant odors. Truck drivers often transport a wide variety of goods, which means that the cabin can pick up odors from the load. These odors can be difficult to remove, so it's a good idea to use a professional wash to remove unpleasant odors from the truck cabin.</p>
            <p>In conclusion, washing the truck cabin is extremely important and should be carried out regularly. Thanks to regular washing, we will ensure a clean and safe environment in the truck cabin, maintain the aesthetics and functionality of the cabin, as well as the smell.</p>
            <p></p>
            <p></p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <FirstScreenEn />
        <Lang />
        <Furnitura />
        <FiveSCreenEn />
        </div>
    );
}
export default ArticelTwo;