import window from './../../../components/img/HopClean/updateArticles/foto10.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>En</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/articel15">Pl</Link>
          ) : (
            <Link to="/en/articel13">En</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>How to clean leather furniture yourself</h2>
            <p>
              Leather furniture is an elegant and durable addition to any
              interior. However, in order to maintain their beauty and
              functionality for many years, proper cleaning and maintenance are
              necessary.
            </p>
            <img className="window" src={window} title="window" alt="window" />

            <p>
              Below you will find a detailed guide on how to clean leather
              furniture yourself:
            </p>
            <ul className="decical-ol">
              <li>
                Preparation. Materials needed:
                <ul>
                  <li> Soft microfiber cloth</li>
                  <li> Vacuum cleaner with crevice nozzle</li>
                  <li>Mild detergent (e.g. leather soap or mild dish soap)</li>
                  <li>
                    Distilled water - Leather lotion or preservative cream
                  </li>
                  <li>Bowl</li>
                </ul>
              </li>
              <li>
                Vacuuming. Start by thoroughly vacuuming the furniture. Use a
                vacuum cleaner with a crevice nozzle to remove dust and dirt
                from nooks and crannies and seams. Vacuuming will help remove
                loose debris that could scratch the leather during further
                cleaning.
              </li>
              <li>
                Preparing the cleaning solution. Mix a small amount of mild
                detergent with distilled water in a bowl. Distilled water is
                better than tap water because it does not contain minerals that
                can accumulate on the skin.
              </li>
              <li>
                Surface cleaning. Dip a soft microfiber cloth into the prepared
                solution, then wring it well so that it is only slightly damp.
                Gently wipe the surface of your leather furniture using circular
                motions. Avoid excessively soaking the leather as this may lead
                to damage.
              </li>
              <li>
                Stain removal. For more difficult stains, such as grease or ink,
                you can use specialist leather stain removers available in
                stores. Always test the product on an inconspicuous part of the
                furniture to make sure it won't damage the material.
              </li>
              <li>
                Care and maintenance. After thoroughly cleaning the furniture,
                leave it to dry naturally, away from direct heat and sunlight.
                When the leather is dry, apply a leather lotion or preservative
                cream. Apply a small amount of the preparation to a soft cloth
                and gently massage into the skin. These types of products help
                moisturize the skin, preventing it from cracking and drying out.
              </li>
              <li>
                Regular maintenance. To ensure that leather furniture retains
                its appearance and properties for many years, vacuum and clean
                it regularly according to the above steps. Use specialized
                leather maintenance products at least once every six months.
              </li>
            </ul>
            <p>
              Cleaning leather furniture yourself is not difficult if you follow
              the tips above. It is crucial to take care of your skin regularly
              so that it retains its appearance and functionality for many
              years. With proper care, your leather furniture will please the
              eye and serve for many years.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreenEn />
      <Lang />
      <Furnitura />
      <FiveSCreenEn />
    </div>
  );
}
export default ArticelTwo;
