import React, { useState } from 'react';
import FirstScreenEn from '../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../FiveScreenEn/FiveScreen';
import './Services.css';

import '../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>En</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/cennik">Pl</Link>
          ) : (
            <Link to="/en/cennik">En</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

export function Price() {
  return (
    <>
      <div className="price">
        <h2 className="other">Price list</h2>
        <div className="outsidewidthtable">
          <p className="lastquestion">
            {' '}
            <span className="attantion">
              The prices listed below are indicative. The price depends on the
              degree of contamination and order quantity.
            </span>
            <span className="attantion">
              You can find out the final price by calling us or sending a photo
              of what needs to be cleaned and our employee will give you the
              exact price.
            </span>
            {/* <span className="attantion attantionbold">
              Minimum order amount 250 PLN
            </span> */}
          </p>
          <table className="table">
            <tbody>
              <tr>
                <th className="thleft">Service name</th>
                <th>Cost (pln)</th>
              </tr>
              <tr>
                <td colSpan="2" className="priceOne">
                  PRICE LIST FOR FURNITURE UPHOLSTERY WASHING:
                </td>
              </tr>
              <tr>
                <td>Chair</td>
                <td className="center"> od 10 do 30</td>
              </tr>
              <tr>
                <td>Pufa</td>
                <td className="center"> od 20 do 50</td>
              </tr>
              <tr>
                <td>Armchair</td>
                <td className="center">od 20 do 80</td>
              </tr>
              <tr>
                <td>Couch</td>
                <td className="center">od 80 do 250</td>
              </tr>
              <tr>
                <td>Corner</td>
                <td className="center">od 100 do 400</td>
              </tr>
              <tr>
                <td> Mattresses </td>
                <td className="center"> od 100 do 400</td>
              </tr>
              <tr>
                <td>Washing soft toys</td>
                <td className="center"> od 10 do 200</td>
              </tr>
              <tr>
                <td>Washing carrycots for strollers</td>
                <td className="center"> od 50 do 200</td>
              </tr>
              <tr>
                <td>Washing children's car seats</td>
                <td className="center">od 50 do 200</td>
              </tr>
              <tr>
                <td colSpan="2" className="priceOne">
                  CARPET CLEANING PRICE LIST:
                </td>
              </tr>
              <tr>
                <td>Rug</td>
                <td className="center"> od 10 do 50 pln/m2</td>
              </tr>

              <tr>
                <td>Carpets</td>
                <td className="center"> od 2 do 30 pln/m2</td>
              </tr>

              <tr>
                <td colSpan="2" className="priceOne">
                  Cleaning in the car:
                </td>
              </tr>
              <tr>
                <td>Basic upholstery wash (front seats)</td>
                <td className="center"> od 50 do 100</td>
              </tr>
              <tr>
                <td>Advanced washing (armchairs, sofa)</td>
                <td className="center"> od 150 do 200</td>
              </tr>
              <tr>
                <td>
                  Expert laundry (seats, sofa, sides, carpet, headliner, trunk)
                </td>
                <td className="center"> od 400 do 1000</td>
              </tr>
            </tbody>
          </table>

          <p className="lastquestion">
            {' '}
            <span>We invite you to use our services!</span>
          </p>
        </div>
      </div>
    </>
  );
}

function Services() {
  return (
    <div className="body_wrapper">
      <FirstScreenEn />
      <Lang />
      <Price />
      <FiveSCreenEn />
    </div>
  );
}

export default Services;
