import React from "react";
import Container from "./Container";
import './SecondScreen.css';

function SecondScreen () {
    return (
        <div className="second_screen">
           <Container />
        </div>
    );
}

export default SecondScreen;