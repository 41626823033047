import React from "react";
import Header from "./Header";
import LangTel from "./TelSubmit";


function ContainerOne () {
    return (
        <div className="first_screen">
        <div className="containerOne"> 
        <LangTel />
        <Header />
        </div>
        </div>
    )
}

export default ContainerOne;