import FiveScreen from '../../FiveScreen/FiveScreen';
import window from './../../img/HopClean/updateArticles/foto2.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FirstScreen from '../../FirstScreen/ContainerOne';
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>Pl</span>
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/en/articel23">En</Link>
          ) : (
            <Link to="/articel13">Pl</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}
function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Jak samodzielnie wyczyścić dziecięcy wózek w domu?</h2>
            <p>
              Czyszczenie dziecięcego wózka jest niezbędne, aby zapewnić dziecku
              komfort i higieniczne warunki podczas spacerów. Regularne
              utrzymanie czystości wózka przedłuża również jego żywotność.
              Poniżej przedstawiam krok po kroku, jak samodzielnie wyczyścić
              dziecięcy wózek w domu.
            </p>
            <img className="window" src={window} title="window" alt="window" />
            <p>Co będzie potrzebne?</p>
            <ul className="decical-ol">
              <li>Ciepła woda</li>
              <li>Delikatny detergent (najlepiej bezzapachowy)</li>
              <li> Gąbka lub miękka ściereczka</li>
              <li>Szczoteczka do zębów (do trudno dostępnych miejsc)</li>
              <li>Odkurzacz ręczny (opcjonalnie)</li>
              <li>Ręczniki papierowe lub bawełniane</li>
              <li>Plastikowa miska</li>
              <li>Środki do czyszczenia metalu/plastiku (opcjonalnie)</li>
              <li>Środek dezynfekujący (bezpieczny dla dzieci)</li>
            </ul>
            <p>
              Krok 1: Przygotowanie Przed rozpoczęciem czyszczenia dokładnie
              obejrzyj wózek. Sprawdź, które części można zdemontować i czy są
              jakieś instrukcje od producenta dotyczące czyszczenia. Zdejmij
              wszystkie ruchome elementy, takie jak pokrowce, poduszki czy pasy
              bezpieczeństwa.
            </p>
            <p>
              Krok 2: Pranie tkanin Zdejmowane tkaniny (pokrowce, wkładki)
              należy wyprać zgodnie z zaleceniami producenta. Większość tkanin
              można prać w pralce na delikatnym cyklu, używając łagodnego
              detergentu. Po praniu pozostaw je do wyschnięcia na powietrzu.
            </p>
            <p>
              Krok 3: Czyszczenie ramy Rama wózka najczęściej jest wykonana z
              metalu lub plastiku. Przygotuj roztwór ciepłej wody z delikatnym
              detergentem. Użyj gąbki lub miękkiej ściereczki, aby dokładnie
              wyczyścić całą ramę. Szczególną uwagę zwróć na miejsca, gdzie
              gromadzi się brud, takie jak złącza i kółka. Do trudno dostępnych
              miejsc użyj szczoteczki do zębów.
            </p>
            <p>
              Krok 4: Czyszczenie kółek Kółka często zbierają najwięcej brudu.
              Usuń je, jeśli to możliwe, i dokładnie wyczyść, używając tej samej
              mieszanki wody i detergentu. Sprawdź, czy w mechanizmach nie ma
              zaschniętego błota lub innych zanieczyszczeń. Po czyszczeniu kółka
              dokładnie osusz.
            </p>
            <p>
              Krok 5: Czyszczenie pasów i drobnych elementów Pasy bezpieczeństwa
              i inne drobne elementy należy czyścić ręcznie. Możesz użyć
              wilgotnej ściereczki z odrobiną detergentu. Dokładnie przetrzyj
              pasy i elementy, a następnie osusz ręcznikiem papierowym.
            </p>
            <p>
              Krok 6: Dezynfekcja Po dokładnym umyciu wszystkich części wózka,
              przetrzyj je środkiem dezynfekującym. Upewnij się, że używasz
              produktu bezpiecznego dla dzieci i pozwól, aby wszystkie elementy
              dobrze wyschły przed ponownym złożeniem wózka.
            </p>
            <p>
              Krok 7: Składanie wózka Gdy wszystkie części są suche i czyste,
              złóż wózek ponownie. Sprawdź, czy wszystkie elementy są dobrze
              zamocowane i czy wózek działa prawidłowo.
            </p>
            <p>
              Regularne czyszczenie dziecięcego wózka nie tylko poprawia jego
              wygląd, ale także zapewnia zdrowe i bezpieczne warunki dla Twojego
              dziecka. Warto wykonywać te czynności co kilka tygodni, aby
              utrzymać wózek w idealnym stanie.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <Lang />
      <Furnitura />
      <FiveScreen />
    </div>
  );
}
export default ArticelTwo;
