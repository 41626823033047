import FiveScreen from '../../FiveScreen/FiveScreen';
import window from './../../img/HopClean/updateArticles/foto1.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FirstScreen from '../../FirstScreen/ContainerOne';
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>Pl</span>
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/en/articel24">En</Link>
          ) : (
            <Link to="/articel13">Pl</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}
function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>
              Doskonała Obsługa dla Twojej Gastronomii - Poznaj Naszą Firmę!
            </h2>
            <p>
              Jesteśmy liderem w branży usług gastronomicznych, oferując
              kompleksowe rozwiązania dla restauracji, barów oraz kawiarni.
              Nasza firma jest odpowiedzią na Twoje potrzeby, zapewniając
              wysokiej jakości produkty i profesjonalną obsługę, której możesz
              zaufać.
            </p>
            <img className="window" src={window} title="window" alt="window" />
            <p>Dlaczego warto wybrać naszą firmę?</p>
            <ul className="decical-ol">
              <li>
                Bogate doświadczenie: Mamy wieloletnie doświadczenie w branży
                gastronomicznej, dzięki czemu doskonale rozumiemy potrzeby
                naszych klientów.
              </li>
              <li>
                Szeroki asortyment: Oferujemy szeroką gamę produktów, w tym
                świeże produkty spożywcze, wysokiej jakości alkohole oraz
                profesjonalne wyposażenie restauracyjne.
              </li>
              <li>
                Indywidualne podejście: Rozumiemy, że każdy lokal gastronomiczny
                jest inny. Dlatego dostosowujemy nasze usługi do specyficznych
                wymagań i preferencji klientów.
              </li>
              <li>
                Konkurencyjne ceny: Oferujemy atrakcyjne ceny i elastyczne
                warunki współpracy, co sprawia, że nasze rozwiązania są dostępne
                dla każdego rodzaju lokalu gastronomicznego.
              </li>
              <li>
                Profesjonalizm i zaufanie: Nasza firma dba o wysoką jakość
                obsługi i terminowość dostaw, zapewniając pełne zadowolenie
                naszych klientów.
              </li>
            </ul>
            <p>
              <strong>Skontaktuj się z nami już dziś!</strong>
            </p>
            <p>
              Zaufaj profesjonalistom, którzy zadbaszą o rozwój Twojej
              gastronomii. Skontaktuj się z nami, aby dowiedzieć się więcej o
              naszych usługach i ofercie dostosowanej do Twoich potrzeb.
            </p>
            <p>
              Nie czekaj dłużej - współpraca z nami to klucz do sukcesu Twojego
              lokalu gastronomicznego!
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <Lang />
      <Furnitura />
      <FiveScreen />
    </div>
  );
}
export default ArticelTwo;
