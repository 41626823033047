import React from "react";
import './ContainerThreeElements.css';
import Gallery from "./Gallery";
import { Link } from "react-router-dom";

function ContainerThreeH3H2 () {
    return (
        <div>
            <h3>Our works</h3>
            <h2>Washing car upholstery, carpets and carpets</h2>
        </div>  
    );
}
function Check () {
    return (
        <div className="check">
           <li><Link to="/en/galiria">More details...</Link></li>
        </div>
    );
}
function ContainerThreeElements () {
    return (
        <div className="containerThree">
            <ContainerThreeH3H2 />
            <Gallery />
            <Check />
        </div>
    );
}

export default ContainerThreeElements;