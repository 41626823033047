
import FiveScreen from '../../FiveScreen/FiveScreen';
import window from './../../img/HopClean/galler.jpg';
import { Cataloge } from "./ArticelOne";

import React, { useState } from "react";
import { Link } from "react-router-dom";
import FirstScreen from "../../FirstScreen/ContainerOne";
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';


function Lang () {
    const [isLang, setLang] = useState(false);
    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>Pl</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/en/articelfour'>En</Link> :<Link to='/articelfour'>Pl</Link>} </button>
        </div>
        </>
    )
}

function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Zalecana częstotliwość czyszczenia materaca</h2>
            <p>
            Materac jest jednym z najważniejszych elementów wyposażenia naszych sypialni, ale często zapominamy, że również wymaga regularnego czyszczenia. Czyszczenie materaca w domu jest kluczowe, ponieważ pozwala na usunięcie zanieczyszczeń, bakterii i roztoczy, które mogą prowadzić do problemów zdrowotnych, takich jak astma, alergie i problemy skórne. W tym artykule omówimy, jak często powinniśmy czyścić materac w domu i jakie metody można stosować.
            </p>
            <p>
            Częstotliwość czyszczenia materaca w dużej mierze zależy od indywidualnych potrzeb i warunków domowych, ale w ogólnym rozrachunku zaleca się, aby materace były czyszczone przynajmniej co sześć miesięcy. Oczywiście, istnieją okoliczności, kiedy konieczne jest wykonanie czyszczenia w krótszym czasie. Na przykład, jeśli ktoś ma alergię, to zaleca się czyszczenie materaca co 3-4 miesiące, aby uniknąć nagromadzenia alergenów.
            </p>
            <img className="quilon" src={window} title='window' alt="window"/>
            <p>
            Istnieje wiele sposobów na czyszczenie materaca, w tym:
            </p>
            <ul className="windowraz">
                <li>Odkurzanie to podstawowa metoda usuwania kurzu i brudu z materaca. Warto jednak zainwestować w odkurzacz z filtrem HEPA, który pozwala na usunięcie alergenów z powietrza. Odkurzanie należy wykonywać regularnie, aby uniknąć nagromadzenia kurzu i brudu.</li>
                <li>
                Odkurzanie to podstawowa metoda usuwania kurzu i brudu z materaca. Warto jednak zainwestować w odkurzacz z filtrem HEPA, który pozwala na usunięcie alergenów z powietrza. Odkurzanie należy wykonywać regularnie, aby uniknąć nagromadzenia kurzu i brudu.
                </li>
                <li>
                Oczyszczanie parą to innowacyjna metoda czyszczenia, która pozwala na usunięcie zanieczyszczeń, roztoczy i bakterii z materaca. Para wodna, która jest wykorzystywana w tej metodzie, penetruje materac, dezynfekując go i usuwając zanieczyszczenia. Po użyciu tej metody, materac powinien zostać wysuszony przez co najmniej 24 godziny.
                </li>
            </ul>
            <p>
            Podsumowanie.
            Czyszczenie materaca w domu jest kluczowe dla utrzymania higieny i zdrowia. Materac powinien być czyszczony co najmniej co 6 miesięcy.
            </p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <FirstScreen />
        <Lang />
        <Furnitura />
        <FiveScreen />
        </div>
    );
}
export default ArticelTwo;