import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import furnit from '../../img/HopClean/articel2.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';

import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>En</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/articeltwo">Pl</Link>
          ) : (
            <Link to="/en/articeltwo">En</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Laundry of mattresses</h2>
            <p>
              The mattress is one of the most important elements of our bedroom,
              and at the same time the place where we spend about a third of our
              lives. Therefore, it is important that our mattress is clean and
              hygienic. Regularly washing your mattresses is crucial to keeping
              them clean and hygienic, and in this article we'll tell you a few
              reasons why it's so important.
            </p>
            <p>
              Firstly, washing mattresses removes contaminants such as dust,
              mites, mold, bacteria and viruses that accumulate in the mattress
              over time. These pollutants can cause allergies, asthma and other
              diseases, as well as affect the quality of sleep. That's why it's
              important to wash your mattress regularly to remove these harmful
              contaminants.
            </p>
            <p>
              Secondly, washing mattresses affects the aesthetics and
              functionality of our mattress. A clean mattress looks more
              aesthetic and contributes to a better feeling in the bedroom. In
              addition, washing mattresses can also improve the comfort of our
              sleep by removing stains, odors and other dirt that can affect the
              quality of sleep.
            </p>
            <p>
              Secondly, washing mattresses affects the aesthetics and
              functionality of our mattress. A clean mattress looks more
              aesthetic and contributes to a better feeling in the bedroom. In
              addition, washing mattresses can also improve the comfort of our
              sleep by removing stains, odors and other dirt that can affect the
              quality of sleep.
            </p>
            <img
              className="quilon2"
              src={furnit}
              title="фурнітура"
              alt="фурнітура"
            />
            <p>
              Thirdly, washing the mattress can extend its life. Mattresses are
              expensive investments, so it's important to take care of their
              durability. Regular washing can help keep your mattress clean and
              hygienic as well as prevent it from wearing out. Thanks to this,
              we will be able to enjoy our mattress for a longer time.
            </p>
            <p>
              Fourth, washing mattresses can help fight odors. Mattresses often
              accumulate odors from sweat, food and other sources that can be
              difficult to remove. Washing mattresses allows you to remove these
              odors, which positively affects the comfort of sleep and the
              quality of air in our bedroom.
            </p>
            <p>
              In conclusion, washing mattresses is extremely important and
              should be carried out regularly. Thanks to regular washing, we
              ensure the cleanliness and hygiene of our mattress, improve sleep
              comfort, extend the life of our mattress and prevent the
              development of harmful impurities. Washing mattresses is a simple
              and effective solution that will allow us to enjoy our mattress
              for a long time.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreenEn />
      <Lang />
      <Furnitura />
      <FiveSCreenEn />
    </div>
  );
}
export default ArticelTwo;
