import React from "react";
import './FourScreen.css';
import ContainerFour from "./ContainerFour";

function FourScreen () {
    return (
        <div className="four_screen">
        <ContainerFour />
        </div>
    );
}

export default FourScreen;