import React from "react";
import Phone2 from './../img/phone2.png';
import './Contact.css';
import { Link } from "react-router-dom";
import facebook from '../img/HopClean/facebook.png';
import youtube from '../img/HopClean/youtube.png';
import instargram from '../img/HopClean/instagram.png';
import ticktock from '../img/HopClean/tiktok.png';

function FooterH34 () {
    return (
        <div className="footerH34">
            <h4>Hof Clean</h4>
            <h3>Furniture cleaning</h3>
        </div>
    );
}
function TelFooter () {
    return (
        <div className="telFooter">
           <Link className="phoneFooter" to="tel:+48506778684"> <img src={Phone2} alt="no painting"/> <span>+48 506 778 684</span></Link> 
           <div className="linksFooter">
            <Link to='https://www.facebook.com/HOFCLEAN/' target="_blanket"><img src={facebook} alt="facebook" title="facebook" /></Link>
            <Link to='https://www.youtube.com/@pranietapicerkikrakow6666'target="_blanket" ><img src={youtube} alt="youtube" title="youtube" /></Link>
            <Link to='https://www.instagram.com/pranie__tapicerki/?igshid=YmMyMTA2M2Y%3D' target="_blanket"><img src={instargram} alt="instagram" title="instagram" /></Link>
            <Link to='https://www.tiktok.com/@hof_clean?_t=8bIJ4r89oc6' target="_blanket"><img src={ticktock} alt="ticktock" title="ticktock" /></Link>
          </div>
        </div>
    );
}
function Contact () {
    return (
        <div className="contact">
            <FooterH34 />
            <TelFooter />
         </div>
    );
}

export default Contact;