import photoContacts from '../img/HopClean/contacts.jpg';
import whatsapp from '../img/HopClean/whatsapp36.png';
import facebook from '../img/HopClean/facebook.png';
import youtube from '../img/HopClean/youtube.png';
import instargram from '../img/HopClean/instagram.png';
import ticktock from '../img/HopClean/tiktok.png';
import React, { useState } from 'react';
import FirstScreenEn from '../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../FiveScreenEn/FiveScreen';
import './Services.css';

import '../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>En</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/kontakt">Pl</Link>
          ) : (
            <Link to="/en/kontakt">En</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

function Contacts() {
  return (
    <div className="body_wrapper">
      <FirstScreenEn />
      <Lang />
      <div className="mainContacts">
        <h2>CONTACT</h2>
        <div>
          <div className="contacts">
            <div className="wrapcontacts">
              <div className="numerPhone">
                <p className="numertel">Phone number:</p>
                <Link to="tel:+48506778684">
                  {' '}
                  <span> +48 506 778 684 </span>
                </Link>
              </div>

              <div className="whatsapp">
                <p className="link">WhatsApp:</p>
                <Link to="https://wa.me/48506778684">
                  <img src={whatsapp} alt="WhatsApp" title="WhatsApp" />{' '}
                </Link>
              </div>
              <div className="email">
                <p className="numertel">Email:</p>
                <Link to="mailto:48732609800v@gmail.com">
                  {' '}
                  <span>48732609800v@gmail.com</span>
                </Link>
              </div>
              <div className="sociatnetwork">
                <p className="link">We are on social networks:</p>
                <div className="linksocial">
                  <Link
                    to="https://www.facebook.com/HOFCLEAN/"
                    target="_blanket"
                  >
                    <img src={facebook} alt="facebook" title="facebook" />
                  </Link>
                  <Link
                    to="https://www.youtube.com/@pranietapicerkikrakow6666"
                    target="_blanket"
                  >
                    <img src={youtube} alt="youtube" title="youtube" />
                  </Link>
                  <Link
                    to="https://www.instagram.com/pranie__tapicerki/?igshid=YmMyMTA2M2Y%3D"
                    target="_blanket"
                  >
                    <img src={instargram} alt="instagram" title="instagram" />
                  </Link>
                  <Link
                    to="https://www.tiktok.com/@hof_clean?_t=8bIJ4r89oc6"
                    target="_blanket"
                  >
                    <img src={ticktock} alt="ticktock" title="ticktock" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="planpracy">
              {/* <h3>Work plan</h3> */}
              <img src={photoContacts} alt="" />
              <p>We work 7 days a week, from 6:00 to 22:00,</p>
              <p>and if the situation requires it also at night.</p>
              <p>We work throughout Poland.</p>
              <p className="hofclean">
                We are a legally operating HOF CLEAN company
              </p>
            </div>
          </div>
        </div>
      </div>

      <FiveSCreenEn />
    </div>
  );
}
export default Contacts;
