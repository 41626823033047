import window from './../../../components/img/HopClean/updateArticles/foto5.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>En</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/articel20">Pl</Link>
          ) : (
            <Link to="/en/articel13">En</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

function Furnitura() {
  return (
    <>
      {' '}
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>How to clean curtains at home yourself</h2>
            <p>
              Curtains not only have a decorative function, but also protect us
              from the sun and provide privacy. Unfortunately, over time they
              accumulate dust, dirt and allergens. Regularly cleaning your
              curtains is therefore essential to keep your home fresh and
              hygienic.
            </p>
            <img className="window" src={window} title="window" alt="window" />
            <p>Here are some steps on how to clean your curtains at home.</p>
            <ul className="decical-ol">
              <li>
                Check the tag. Before you start cleaning your curtains, be sure
                to check the label for washing instructions. Manufacturers often
                provide information on recommended cleaning methods. Some
                curtains are machine washable, while others require a more
                gentle approach, such as hand washing or dry cleaning.
              </li>
              <li>
                Vacuuming. Before washing, it is a good idea to vacuum the
                curtains thoroughly to remove dust and loose dirt. Use a vacuum
                cleaner attachment intended for upholstery or a soft brush.
                Vacuuming can be done regularly, every few weeks, to keep your
                curtains looking fresh.
              </li>
              <li>
                Machine wash. If the label allows machine washing, remove the
                curtains and put them in the drum. Select a delicate washing
                program and use a mild detergent. It is recommended to wash
                curtains at a low temperature (30-40°C) to avoid fabric
                shrinkage. After washing, remove the curtains immediately to
                avoid creases.
              </li>
              <li>
                Hand wash. For delicate curtains that are not suitable for
                washing in a washing machine, hand wash them. Fill a bathtub or
                large bowl with lukewarm water and add a mild detergent. Gently
                soak the curtains and leave them for a few minutes to dissolve
                the dirt. Then gently rinse the curtains with clean water until
                the detergent is completely removed.
              </li>
              <li>
                Drying. After washing, the curtains should be dried properly.
                Avoid tumble drying as this may shrink the fabric or damage
                delicate materials. It is best to air-dry curtains by hanging
                them on a line or in a drying rack. Make sure they are evenly
                distributed to avoid creases.
              </li>
              <li>
                Ironing. If the curtains are creased after drying, you can
                gently iron them. Use an iron at a low temperature and iron the
                curtains inside out to avoid damaging the fabric. In the case of
                curtains made of delicate fabrics, such as silk, it is worth
                using a special iron attachment or ironing through a cotton
                cloth.
              </li>
              <li>
                Dry cleaning. If the curtains are made of very delicate
                materials or are too large to wash at home, it is worth taking
                them to a professional dry cleaner. Specialists will clean the
                curtains thoroughly, ensuring their original appearance and
                quality are preserved.
              </li>
            </ul>
            <p>
              Cleaning curtains at home yourself is not difficult, it only
              requires following a few basic rules. Regular vacuuming, washing
              according to the instructions on the label, and proper drying and
              ironing will ensure that your curtains will look like new for a
              long time. By taking care of our curtains, we also take care of
              the health and comfort of our home.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreenEn />
      <Lang />
      <Furnitura />
      <FiveSCreenEn />
    </div>
  );
}
export default ArticelTwo;
