import window from './../../img/HopClean/photo13.jpg';
import window2 from '../../img/HopClean/photo13_2.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";
import React, {useState} from "react";
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';


function Lang () {
    const [isLang, setLang] = useState(false);
    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>En</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/articel13'>Pl</Link> :<Link to='/en/articel13'>En</Link>} </button>
        </div>
        </>
    )
}


function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>How to clean upholstered sofas and chairs?</h2>
            <p>Several different methods can be used to clean upholstered sofas and chairs, depending on the type of stain, the upholstery material and the cleaning products available. Here are some general tips that may help you:</p>
            <p>Vacuuming: Begin by vacuuming upholstered furniture to remove fine debris, dust and pet hair. Use a soft-tipped brush or a special vacuum cleaner attachment for cleaning upholstery.</p>
                       
            <img className="window" src={window} title='window' alt="window"/>
            <p>Spraying the cleaner: Before cleaning the entire surface of the upholstery, test the selected cleaner on an inconspicuous area to ensure it will not cause stains or damage. You can also check the furniture manufacturer's cleaning recommendations.</p>
            <p>Cleaning Stains: If there are specific stains on the upholstery, you need to locate them first. Different cleaning agents can be used depending on the type of stain. For example:</p>
            <p>Beverage stains: For fresh coffee, tea, juice or red wine stains, you can try applying some cool water with a mild detergent to them and gently wipe them away. Then wipe with a damp cloth to remove any detergent residue and leave no stains.</p>
           
            <img className="window" src={window2} title='window' alt="window"/>
           
            <p>Grease stains: For grease stains, such as food or oil stains, you can try applying washing powder or a special grease stain cleaner to them. Leave it for a while and then gently wipe or vacuum it.</p>
            <p>Ink or dye stains: You can try to remove ink or dye stains with special ink or dye stain cleaners that are available in stores.</p>
            <p>Cleaning the entire surface: After removing the stains, you can proceed to cleaning the entire surface of the furniture. Apply a small amount of a suitable cleaning agent to a sponge or soft brush and gently massage it into the upholstery using circular motions. Then remove excess cleaner with a clean damp cloth.</p>
            <p>Drying: After cleaning the surface of the upholstery, it is important to dry the furniture well. You can use a fan or air conditioner to speed up the drying process. Avoid using furniture until it is fully dry.</p>
            <p>Keep in mind that the above tips are general recommendations, and the effectiveness of cleaning depends on many factors, such as the type of upholstery and the type of stains. If you are not sure how to clean a particular upholstered piece of furniture, it is always worth consulting the manufacturer or an upholstery cleaning specialist.</p>
    
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <FirstScreenEn />
        <Lang />
        <Furnitura />
        <FiveSCreenEn />
        </div>
    );
}
export default ArticelTwo;