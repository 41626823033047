import window from './../../../components/img/HopClean/updateArticles/foto6.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>En</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/articel19">Pl</Link>
          ) : (
            <Link to="/en/articel13">En</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>How to clean plush toys yourself at home</h2>
            <p>
              Plush toys are children's favorites and often become their
              companions on everyday adventures. Unfortunately, over time, they
              accumulate dirt, dust and bacteria, so regular cleaning is
              essential.
            </p>
            <img className="window" src={window} title="window" alt="window" />
            <p>
              Here are some proven methods to effectively and safely clean plush
              toys at home.
            </p>
            <ul className="decical-ol">
              <li>
                Checking the label. Before cleaning, always check the label for
                manufacturer's instructions. You will often find information
                there whether the toy is machine washable or requires manual
                cleaning. The label may also include instructions on appropriate
                temperatures and detergents.
              </li>
              <li>
                Machine wash. If the label allows it, machine washing is the
                most convenient way to clean plush toys.
                <ul>
                  <li>
                    Preparing the toy: Make sure all plastic or electronic
                    components are removed if possible. Toys with small parts or
                    loose parts are best placed in a laundry bag to prevent
                    damage.
                  </li>
                  <li>
                    Selecting a washing program: Select a gentle washing program
                    with low temperature (maximum 30°C) and use a mild
                    detergent. Avoid bleach and harsh chemicals.
                  </li>
                  <li>
                    Drying: After washing, the toy can be air dried. Avoid
                    tumble drying as high temperatures may damage the fabric and
                    shape of the toy. You can gently squeeze out excess water
                    with a towel and leave the toy to dry outdoors.
                  </li>
                </ul>
              </li>
              <li>
                Hand cleaning If the toy is not machine washable, it is best to
                clean it by hand.
                <ul>
                  <li>
                    Preparing the cleaning solution: Mix warm water with mild
                    detergent or baby soap in a bowl.
                  </li>
                  <li>
                    Cleaning: Using a soft sponge or toothbrush, gently wipe the
                    entire surface of the toy, paying special attention to dirty
                    areas. Avoid excessive soaking to avoid damaging the inner
                    filling.
                  </li>
                  <li>
                    Rinsing: Wipe the toy with a damp cloth to remove any
                    detergent residue. Make sure there are no soap residues that
                    may irritate your baby's skin.
                  </li>
                  <li>
                    Drying: Leave the toy to dry outdoors. You can place it in a
                    ventilated place or hang it on a drying rack, avoiding
                    direct sunlight.
                  </li>
                </ul>
              </li>
              <li>
                Disinfection To disinfect a toy and get rid of bacteria and
                mites, you can use several simple methods:
                <ul>
                  <li>
                    Freezer: Place the toy in a plastic bag and place it in the
                    freezer for 24 hours. Low temperature will kill most
                    microorganisms.
                  </li>
                  <li>
                    Baking soda: Sprinkle baking soda on the toy, then gently
                    massage it into the fabric. After a few hours, shake or
                    vacuum the toy to remove any baking soda residue.
                  </li>
                  <li>
                    Vinegar: Water with vinegar (1:1 ratio) is an effective
                    disinfectant. Dampen a cloth in this solution and wipe the
                    surface of the toy.
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              Regular cleaning of plush toys not only extends their life, but
              also ensures hygienic conditions for your child. Thanks to the
              above methods, you can effectively and safely clean your favorite
              stuffed animals at home.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreenEn />
      <Lang />
      <Furnitura />
      <FiveSCreenEn />
    </div>
  );
}
export default ArticelTwo;
