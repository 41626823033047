import window from './../../../components/img/HopClean/updateArticles/foto3.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>En</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/articel22">Pl</Link>
          ) : (
            <Link to="/en/articel13">En</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

function Furnitura() {
  return (
    <>
      {' '}
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>How to clean pet hair yourself at home</h2>
            <p>
              Owning pets is a great joy, but it also comes with some
              challenges, such as cleaning up the fur on a regular basis.
              Especially during the shedding season, hair can accumulate on
              furniture, floors and clothes.
            </p>
            <img className="window" src={window} title="window" alt="window" />
            <p>
              Below I present some effective methods that will help you keep
              your home clean.
            </p>
            <ul className="decical-ol">
              <li>
                Regular vacuuming. Vacuuming is the primary way to remove hair.
                It is worth investing in a vacuum cleaner equipped with special
                tips for collecting hair. Vacuuming should be regular,
                preferably several times a week, to prevent large amounts of
                hair from accumulating.
              </li>
              <li>
                Brushes and rollers for clothes. Clothes brushes and rollers
                with adhesive tape are irreplaceable in the fight against hair
                on furniture and clothes. The rollers perfectly collect hair
                from fabrics, and their use is simple and quick. Alternatively,
                you can use rubber gloves - just wipe them over the surface so
                that the fur sticks to the rubber.
              </li>
              <li>
                Washing with the addition of special agents. Washing clothes and
                textiles with antistatic agents or special washing liquids will
                help remove hair. Before washing, it is worth shaking the
                clothes to get rid of excess hair. In the case of blankets or
                bedspreads, you can use a tumble dryer with a gentle drying
                option, which will also help collect hair.
              </li>
              <li>
                Wet wipes. Wet baby wipes or special fur cleaning wipes can be
                very helpful. They quickly and effectively collect hair from
                various surfaces and additionally refresh fabrics.
              </li>
              <li>
                Regular pet care. Brushing your pets regularly significantly
                reduces the amount of hair that falls on the floor and
                furniture. Depending on the breed and type of fur, it is worth
                choosing the right brush and brushing your pet at least once a
                week.
              </li>
              <li>
                Using air purifiers. Air purifiers with HEPA filters are
                effective at collecting airborne allergens and dander. They also
                help maintain better air quality in your home.
              </li>
              <li>
                Protective covers and blankets. Using furniture covers and
                protective covers makes it easier to keep clean. The covers can
                be washed regularly, which makes it easier to get rid of hair
                and prevents it from accumulating on the furniture.
              </li>
            </ul>
            <p>
              To sum up, regular use of the above methods will help keep your
              home clean and minimize the problem of pet hair. It is important
              not to neglect pet care, which will also help reduce the amount of
              hair in your home.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreenEn />
      <Lang />
      <Furnitura />
      <FiveSCreenEn />
    </div>
  );
}
export default ArticelTwo;
