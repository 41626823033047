import window from './../../../components/img/HopClean/updateArticles/foto9.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>En</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/articel16">Pl</Link>
          ) : (
            <Link to="/en/articel13">En</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>How to clean a carpet yourself at home</h2>
            <p>
              Carpets are an important element of interior design, but at the
              same time they can accumulate dirt, dust and stains. Regularly
              cleaning your carpets not only improves their appearance, but also
              contributes to a healthier home environment.
            </p>
            <img className="window" src={window} title="window" alt="window" />

            <p>
              Here are some proven methods on how to clean the carpet yourself
              at home.
            </p>
            <ul className="decical-ol">
              <li>
                Regular vacuuming. Regular vacuuming is the basis for keeping
                your carpet clean. This should be done at least once a week, and
                even more often in high traffic areas. Vacuuming removes surface
                dust, dirt and allergens.
              </li>
              <li>
                Cleaning stains on an ongoing basis. Carpet stains should be
                removed immediately after they occur to prevent them from being
                permanently absorbed. Here are some home remedies for various
                types of stains:
                <ul>
                  <li>
                    Coffee and tea stains: Mix water and vinegar in a 1:1 ratio,
                    soak a cloth with the solution and gently wipe the stain,
                    starting from the outer edges towards the center.
                  </li>
                  <li>
                    Wine stains: Sprinkle table salt on a fresh stain, which
                    will absorb most of the liquid. Then wash the area with a
                    solution of water and vinegar.
                  </li>
                  <li>
                    Grease stains: Sprinkle the stain with potato flour or
                    baking soda, leave for a few hours, then vacuum.
                  </li>
                </ul>
              </li>
              <li>
                Homemade cleaning products. You can use household cleaning
                products to thoroughly clean the carpet:
                <ul>
                  <li>
                    Baking soda and vinegar solution: Sprinkle the carpet with
                    baking soda and then spray it with a solution of water and
                    vinegar (1:1 ratio). Leave for a few hours until everything
                    dries, then vacuum. Baking soda neutralizes odors and
                    vinegar helps remove dirt.
                  </li>
                  <li>
                    Carpet shampooing: Mix 1 tablespoon of dishwashing liquid, 1
                    tablespoon of white vinegar and 2 cups of warm water. Soak
                    the sponge in the mixture and gently wash the carpet. Once
                    dry, vacuum the carpet.
                  </li>
                </ul>
              </li>
              <li>
                Steam cleaning. Steam cleaning is an effective method for deep
                cleaning carpets. You can rent a carpet steamer or purchase a
                device for home use. Steam cleaning removes dirt and bacteria
                from the deep layers of the carpet, refreshing it.
              </li>
              <li>
                Carpet drying. After each cleaning, the carpet must be
                thoroughly dried to prevent the development of mold and
                unpleasant odors. If possible, ventilate the room or use a fan
                to speed up the drying process.
              </li>
            </ul>
            <p>
              Cleaning the carpet yourself at home is possible and effective if
              you follow the above tips. Regular vacuuming, reacting quickly to
              stains and using household cleaning products will keep your carpet
              in perfect condition for a long time. If your carpet is very
              dirty, you may want to consider professional cleaning from time to
              time.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreenEn />
      <Lang />
      <Furnitura />
      <FiveSCreenEn />
    </div>
  );
}
export default ArticelTwo;
