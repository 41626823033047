import FiveScreen from '../../FiveScreen/FiveScreen';
import window from './../../img/HopClean/updateArticles/foto4.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FirstScreen from '../../FirstScreen/ContainerOne';
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>Pl</span>
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/en/articel21">En</Link>
          ) : (
            <Link to="/articel13">Pl</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}
function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>
              Jak samodzielnie wyczyścić sufit w samochodzie w domowych
              warunkach
            </h2>
            <p>
              Czyszczenie sufitu w samochodzie może wydawać się skomplikowane,
              ale z odpowiednimi narzędziami i technikami można to zrobić
              samodzielnie w domowych warunkach.
            </p>
            <img className="window" src={window} title="window" alt="window" />
            <p>
              Oto krok po kroku, jak wyczyścić sufit w samochodzie, aby wyglądał
              jak nowy.
            </p>
            <p>
              Potrzebne narzędzia i materiały. Zanim rozpoczniesz czyszczenie,
              przygotuj następujące rzeczy:
            </p>

            <ul className="decical-ol">
              <li>Odkurzacz z miękką szczotką</li>
              <li> Miękka szczotka lub gąbka</li>
              <li> Ściereczki z mikrofibry </li>
              <li>
                Preparat czyszczący do tapicerki lub roztwór wody z mydłem
              </li>
              <li> Woda destylowana</li>
              <li> Pojemnik na wodę</li>
              <li> Opcjonalnie: odplamiacz do tapicerki</li>
            </ul>
            <p>
              Krok 1: Przygotowanie Najpierw odkurz sufit samochodu, używając
              odkurzacza z miękką szczotką. Usuń wszelkie luźne
              zanieczyszczenia, takie jak kurz i pył. Upewnij się, że robisz to
              delikatnie, aby nie uszkodzić materiału.
            </p>
            <p>
              Krok 2: Przygotowanie roztworu czyszczącego Jeśli używasz
              specjalnego preparatu do czyszczenia tapicerki, postępuj zgodnie z
              instrukcjami na opakowaniu. Jeśli nie masz takiego preparatu,
              możesz użyć roztworu wody z mydłem. Wymieszaj niewielką ilość
              delikatnego mydła z wodą destylowaną w pojemniku.
            </p>
            <p>
              Krok 3: Testowanie preparatu Zanim przystąpisz do czyszczenia
              całego sufitu, przetestuj wybrany preparat na małej, niewidocznej
              powierzchni. Upewnij się, że nie powoduje on przebarwień ani
              uszkodzeń materiału.
            </p>
            <p>
              Krok 4: Czyszczenie sufitu Nanieś niewielką ilość roztworu
              czyszczącego na miękką szczotkę lub gąbkę. Delikatnie przetrzyj
              sufit, wykonując okrężne ruchy. Pamiętaj, aby nie przemaczać
              materiału, ponieważ nadmiar wilgoci może prowadzić do uszkodzeń i
              powstawania pleśni.
            </p>
            <p>
              Krok 5: Usuwanie plam Jeśli na suficie znajdują się trudne do
              usunięcia plamy, zastosuj odplamiacz do tapicerki. Nanieś go
              bezpośrednio na plamę i delikatnie wetrzyj za pomocą ściereczki z
              mikrofibry. Pozostaw preparat na kilka minut, a następnie
              przetrzyj czystą, wilgotną ściereczką.
            </p>
            <p>
              Krok 6: Suszenie Po zakończeniu czyszczenia, pozostaw drzwi
              samochodu otwarte, aby umożliwić sufitowi wyschnięcie. Możesz
              również użyć wentylatora, aby przyspieszyć ten proces. Upewnij
              się, że sufit jest całkowicie suchy, zanim zamkniesz drzwi
              samochodu.
            </p>
            <p>
              Krok 7: Finalne odkurzanie Gdy sufit jest już suchy, ponownie
              odkurz go, aby usunąć ewentualne resztki brudu i środka
              czyszczącego. Użyj miękkiej szczotki, aby nie uszkodzić materiału.
            </p>
            <p>
              Czyszczenie sufitu w samochodzie może być czasochłonne, ale jest
              to zadanie, które można wykonać samodzielnie w domowych warunkach.
              Regularne czyszczenie sufitu pomoże utrzymać wnętrze samochodu w
              czystości i świeżości. Dzięki naszym wskazówkom, twój sufit będzie
              wyglądał jak nowy bez konieczności korzystania z profesjonalnych
              usług czyszczących.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <Lang />
      <Furnitura />
      <FiveScreen />
    </div>
  );
}
export default ArticelTwo;
