import FiveScreen from '../../FiveScreen/FiveScreen';
import window from './../../img/HopClean/articel14.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FirstScreen from '../../FirstScreen/ContainerOne';
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>Pl</span>
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/en/articel14">En</Link>
          ) : (
            <Link to="/articel14">Pl</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}
function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Czyszczenie Tapicerki skórzanej</h2>
            <p>
              🌟 Przywróć blask swojemu meblowi skórzanej dzięki naszej
              profesjonalnej usłudze czyszczenia chemicznego w Krakowie! 🌟
            </p>
            <p>
              🛋️ Czy jesteś zmęczony plamami i zabrudzeniami na ulubionym meblu
              skórzanym? Nie zniechęcaj się! Nasz zespół ekspertów jest gotowy
              przywrócić twojej sofie, fotelowi czy krzesłom pierwotny wygląd za
              pomocą najnowszych metod czyszczenia chemicznego.
            </p>
            <img className="window" src={window} title="window" alt="window" />

            <p>🌈 Odczuj różnicę dzięki naszym usługom:</p>
            <ul className="windowraz">
              <li>
                Profesjonalne podejście: Nasi specjaliści posiadają wieloletnie
                doświadczenie w pracy z meblami skórzanymi i wykorzystują
                najnowocześniejsze techniki, aby zapewnić najlepsze rezultaty.
              </li>
              <li>
                Bezpieczeństwo Twojego mebla: Dzięki naszym delikatnym środkom
                czyszczącym i profesjonalnej wiedzy możesz mieć pewność, że Twój
                mebel jest w bezpiecznych rękach.
              </li>
              <li>
                Skuteczne usuwanie plam: Niezależnie od tego, czy są to plamy po
                napojach, tłuszcze czy inne substancje, nasze metody czyszczenia
                skutecznie pozbywają się nawet najtrudniejszych zabrudzeń.
              </li>
              <li>
                Ochrona i odświeżenie: Poza czyszczeniem nasze usługi obejmują
                także zabezpieczanie i odświeżanie skóry, co przedłuża jej
                trwałość i zachowuje jej piękny wygląd na dłużej.
              </li>
              <li>
                Dostępność i elastyczność: Jesteśmy dostępni w dogodnych dla
                Ciebie terminach, a nasze usługi są elastycznie dostosowywane do
                Twoich potrzeb i preferencji.
              </li>
            </ul>
            <p>
              Nie czekaj dłużej! Skorzystaj z naszej usługi czyszczenia
              chemicznego mebli skórzanych i ciesz się ich nieskazitelnym
              wyglądem już dziś! 🌟
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <Lang />
      <Furnitura />
      <FiveScreen />
    </div>
  );
}
export default ArticelTwo;
