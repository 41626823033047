import glass from '../../img/HopClean/contacts.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";

import React, { useState } from "react";
import { Link } from "react-router-dom";
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';


function Lang () {
    const [isLang, setLang] = useState(false);
    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>En</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/articelthree'>Pl</Link> :<Link to='/en/articelthree'>En</Link>} </button>
        </div>
        </>
    )
}


function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>How often should you clean upholstered furniture in the office</h2>
            <p>
            Upholstered furniture is a popular choice for offices due to its comfort and aesthetics. However, to keep them clean and durable, they should be cleaned regularly. In this article, we will discuss how often you should clean upholstered furniture in your office and why it is important.
            </p>
            <p>
            First of all, it is worth remembering that upholstered furniture is susceptible to the accumulation of dust, dirt, mites and other allergens. These substances can affect office air quality and lead to allergies and other health problems. That is why it is so important to regularly clean upholstered furniture.
            </p>
            <p>
            In general, it is recommended that upholstered furniture in the office be cleaned at least quarterly. However, if your office gets a lot of traffic and your furniture is heavily used, you may need to clean it more often. In this case, it is recommended to clean the furniture at least once a month.
            </p>
            <img className="quilon" src={glass} title='фурнітура' alt="фурнітура"/>
            <p>
            When it comes to the cleaning method, there are many ways including vacuuming, dry cleaning, wet cleaning and steam cleaning. The choice of the appropriate method depends on the type of upholstery, the degree of soiling and the preferences and budget of the office. In the case of heavily soiled furniture, it is recommended to use the services of professional cleaning companies.
            </p>
            <p>
            It is also important to regularly remove stains from upholstered furniture. In the case of larger stains, it is recommended to remove them as soon as possible to prevent more difficult stains and dirt. To remove the stains, you can use special cleaners or home remedies, such as the use of soapy water or a vinegar solution.
            </p>
            <p>
            In conclusion, upholstered furniture in the office should be cleaned regularly to keep it clean and durable, and to prevent the accumulation of dust, dirt and allergens. It is recommended that you clean your furniture at least quarterly, but if your furniture is heavily used, it may need to be cleaned more often. The choice of the appropriate cleaning method depends on the type of upholstery and the degree of soiling.
            </p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <FirstScreenEn />
        <Lang />
        <Furnitura />
        <FiveSCreenEn />
        </div>
    );
}
export default ArticelTwo;