import FiveScreen from '../../FiveScreen/FiveScreen';
import window from './../../img/HopClean/updateArticles/foto7.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FirstScreen from '../../FirstScreen/ContainerOne';
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>Pl</span>
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/en/articel18">En</Link>
          ) : (
            <Link to="/articel18">Pl</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}
function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Jak samodzielnie wyczyścić materac w domowych warunkach</h2>
            <p>
              Czysty materac to podstawa zdrowego i komfortowego snu. Niestety,
              z czasem materace zbierają kurz, plamy i różne zanieczyszczenia.
              Regularne czyszczenie materaca nie tylko przedłuża jego żywotność,
              ale również poprawia higienę i jakość snu.
            </p>
            <img className="window" src={window} title="window" alt="window" />
            <p>
              Oto kilka kroków, które pomogą ci samodzielnie wyczyścić materac w
              domowych warunkach.
            </p>
            <ul className="decical-ol">
              <li>
                Odkurzanie materaca. Pierwszym krokiem jest dokładne odkurzenie
                materaca. Użyj odkurzacza z odpowiednią końcówką, aby usunąć
                kurz, włosy i inne drobne zanieczyszczenia. Skup się szczególnie
                na szwach i załamaniach materaca, gdzie najczęściej gromadzi się
                brud.
              </li>
              <li>
                Usuwanie plam Do usuwania plam z materaca możesz użyć kilku
                domowych środków:
                <ul>
                  <li>
                    Plamy z krwi: Najlepiej działają zimna woda i mydło. Namocz
                    gąbkę w zimnej wodzie z dodatkiem delikatnego mydła i
                    delikatnie pocieraj plamę. Unikaj ciepłej wody, ponieważ
                    może ona utrwalić plamę.
                  </li>
                  <li>
                    Plamy z moczu: Przygotuj mieszankę wody i octu (w proporcji
                    1:1) i spryskaj nią plamę. Następnie posyp sodą oczyszczoną
                    i pozostaw na kilka godzin. Soda pochłonie wilgoć i zapach.
                    Na koniec odkurz sodę.
                  </li>
                  <li>
                    Inne plamy: Możesz użyć pasty z sody oczyszczonej i wody.
                    Nałóż pastę na plamę, poczekaj, aż wyschnie, a następnie
                    odkurz.
                  </li>
                </ul>
              </li>
              <li>
                Dezynfekcja. Aby zdezynfekować materac i pozbyć się bakterii
                oraz roztoczy, możesz użyć mieszanki wody i olejku z drzewa
                herbacianego. Wymieszaj kilka kropel olejku z wodą w butelce z
                rozpylaczem i spryskaj materac. Pamiętaj, aby nie przemoczyć
                materaca – wystarczy lekkie zwilżenie powierzchni.
              </li>
              <li>
                Wietrzenie i suszenie. Po zakończeniu czyszczenia, pozostaw
                materac na kilka godzin w dobrze wentylowanym pomieszczeniu.
                Jeśli to możliwe, wystaw go na słońce, które dodatkowo pomoże w
                dezynfekcji i wysuszeniu materaca.
              </li>
              <li>
                Ochrona materaca. Aby uniknąć częstego czyszczenia, warto
                zainwestować w pokrowiec na materac. Pokrowce są łatwe do prania
                i skutecznie chronią materac przed zabrudzeniami i zniszczeniem.
              </li>
            </ul>
            <p>
              Czyszczenie materaca nie jest trudnym zadaniem, jeśli
              przestrzegasz kilku prostych zasad. Regularne odkurzanie, usuwanie
              plam, dezynfekcja i wietrzenie materaca zapewnią ci zdrowy i
              komfortowy sen. Pamiętaj również o stosowaniu ochronnych
              pokrowców, które znacznie ułatwią utrzymanie materaca w czystości.
              Dzięki tym prostym krokom twój materac będzie służył ci przez
              wiele lat, zapewniając komfort i higienę snu.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <Lang />
      <Furnitura />
      <FiveScreen />
    </div>
  );
}
export default ArticelTwo;
