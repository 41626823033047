import window from './../../../components/img/HopClean/updateArticles/foto7.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>En</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/articel18">Pl</Link>
          ) : (
            <Link to="/en/articel13">En</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

function Furnitura() {
  return (
    <>
      {' '}
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>How to clean a mattress yourself at home</h2>
            <p>
              A clean mattress is the basis for healthy and comfortable sleep.
              Unfortunately, over time, mattresses collect dust, stains and
              various contaminants. Regularly cleaning your mattress not only
              extends its life, but also improves hygiene and sleep quality.
            </p>
            <img className="window" src={window} title="window" alt="window" />
            <p>
              Here are some steps that will help you clean your mattress at
              home.
            </p>
            <ul className="decical-ol">
              <li>
                Vacuuming the mattress. The first step is to thoroughly vacuum
                the mattress. Use a vacuum cleaner with the appropriate
                attachment to remove dust, hair and other small debris. Pay
                particular attention to the seams and creases of the mattress,
                where dirt most often accumulates.
              </li>
              <li>
                Removing stains You can use several home remedies to remove
                stains from your mattress:
                <ul>
                  <li>
                    Blood stains: Cold water and soap work best. Soak the sponge
                    in cold water with mild soap and gently rub the stain. Avoid
                    warm water as it may set the stain.
                  </li>
                  <li>
                    Urine stains: Prepare a mixture of water and vinegar (1:1
                    ratio) and spray it on the stain. Then sprinkle with baking
                    soda and leave for a few hours. The baking soda will absorb
                    moisture and odor. Finally, vacuum up the baking soda.
                  </li>
                  <li>
                    Other stains: You can use a paste of baking soda and water.
                    Apply the paste to the stain, wait for it to dry, and then
                    vacuum it up.
                  </li>
                </ul>
              </li>
              <li>
                Disinfection. To disinfect the mattress and get rid of bacteria
                and dust mites, you can use a mixture of water and tea tree oil.
                Mix a few drops of the oil with water in a spray bottle and
                spray the mattress. Remember not to soak the mattress - just
                slightly moisten the surface.
              </li>
              <li>
                Airing and drying. After cleaning, leave the mattress for
                several hours in a well-ventilated room. If possible, expose it
                to the sun, which will further help disinfect and dry the
                mattress.
              </li>
              <li>
                Mattress protection. To avoid frequent cleaning, it is worth
                investing in a mattress cover. The covers are easy to wash and
                effectively protect the mattress against dirt and damage.
              </li>
            </ul>
            <p>
              Cleaning your mattress is not a difficult task if you follow a few
              simple rules. Regular vacuuming, removing stains, disinfecting and
              airing the mattress will ensure a healthy and comfortable sleep.
              Also remember to use protective covers, which will make it much
              easier to keep the mattress clean. Thanks to these simple steps,
              your mattress will serve you for many years, ensuring comfort and
              sleep hygiene.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreenEn />
      <Lang />
      <Furnitura />
      <FiveSCreenEn />
    </div>
  );
}
export default ArticelTwo;
