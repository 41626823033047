import React from "react";
import './ThirdScreen.css';
import ContainerThree from "./ContainerThree";

function ThirdScreen () {
    return (
        <div className="third_screen">
         <ContainerThree />
        </div>
    )
}

export default ThirdScreen;