import React, { useState } from 'react';
import furnit from '../../img/HopClean/articel2.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import FirstScreen from '../../FirstScreen/ContainerOne';
import FiveSCreen from '../../FiveScreen/FiveScreen';
import '../../Pages/Services.css';

import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>Pl</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/en/articeltwo">En</Link>
          ) : (
            <Link to="/articeltwo">Pl</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Pranie materacy</h2>
            <p>
              Materac jest jednym z najważniejszych elementów naszej sypialni, a
              zarazem miejscem, w którym spędzamy około jednej trzeciej naszego
              życia. Dlatego ważne jest, aby nasz materac był czysty i
              higieniczny. Regularne pranie materacy jest kluczowe dla
              utrzymania czystości i higieny, a w tym artykule przedstawimy
              kilka powodów, dla których jest to tak istotne.
            </p>
            <p>
              Po pierwsze, pranie materacy pozwala na usunięcie zanieczyszczeń,
              takich jak kurz, roztocza, pleśnie, bakterie i wirusy, które
              gromadzą się w materacu w ciągu czasu. Te zanieczyszczenia mogą
              powodować alergie, astmę i inne choroby, a także wpływać na jakość
              snu. Dlatego ważne jest, aby regularnie prać materac, aby usunąć
              te szkodliwe zanieczyszczenia.
            </p>
            <p>
              Po drugie, pranie materacy wpływa na estetykę i funkcjonalność
              naszego materaca. Czysty materac wygląda bardziej estetycznie i
              przyczynia się do lepszego samopoczucia w sypialni. Ponadto,
              pranie materacy może również poprawić komfort naszego snu poprzez
              usuwanie plam, zapachów i innych zabrudzeń, które mogą wpływać na
              jakość snu.
            </p>
            <img
              className="quilon2"
              src={furnit}
              title="фурнітура"
              alt="фурнітура"
            />
            <p>
              Po trzecie, pranie materacy może przedłużyć jego żywotność.
              Materace są kosztownymi inwestycjami, dlatego ważne jest, aby dbać
              o ich trwałość. Regularne pranie może pomóc w utrzymaniu czystości
              i higieny materaca, a także w zapobieganiu jego zużyciu. Dzięki
              temu będziemy mogli cieszyć się naszym materacem przez dłuższy
              czas.
            </p>
            <p>
              Po czwarte, pranie materacy może pomóc w zwalczaniu nieprzyjemnych
              zapachów. Materace często gromadzą zapachy z potu, jedzenia i
              innych źródeł, które mogą być trudne do usunięcia. Pranie materacy
              pozwala na usunięcie tych zapachów, co pozytywnie wpływa na
              komfort snu i jakość powietrza w naszej sypialni.
            </p>
            {/* <img className="furnit2" src={furnit2} title='фурнітура' alt="фурнітура"/> */}
            <p>
              Podsumowując, pranie materacy jest niezwykle ważne i powinno być
              regularnie przeprowadzane. Dzięki regularnemu praniu zapewniamy
              sobie czystość i higienę naszego materaca, poprawiamy komfort snu,
              przedłużamy żywotność naszego materaca i zapobiegamy rozwojowi
              szkodliwych zanieczyszczeń. Pranie materacy to proste i skuteczne
              rozwiązanie, które pozwoli nam cieszyć się naszym materacem przez
              długi czas.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <Lang />
      <Furnitura />
      <FiveSCreen />
    </div>
  );
}
export default ArticelTwo;
