import window from './../../img/HopClean/article9.jpg';
import window2 from '../../img/HopClean/article3.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";
import React, {useState} from "react";
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';


function Lang () {
    const [isLang, setLang] = useState(false);
    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>En</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/articel9'>Pl</Link> :<Link to='/en/articel9'>En</Link>} </button>
        </div>
        </>
    )
}

function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Washing curtains, curtains, blinds</h2>
            <p>
            Washing curtains, curtains and blinds is an often overlooked activity in keeping our homes clean. However, this is a very important step that should not be ignored. Washing these elements of window equipment is not only a matter of aesthetics, but above all health. Below are a few reasons why washing curtains, drapes and blinds is so important.
            </p>
            <p>
            Firstly, curtains, curtains and blinds collect a lot of dust, pollen and other pollutants that can cause respiratory problems. Many people suffer from allergies and asthma, and such pollutants can adversely affect their health. Washing these items regularly can help remove pollutants and improve the air quality in our homes.
            </p>
            <img className="window" src={window} title='window' alt="window"/>
            <p>
            Secondly, curtains, drapes and blinds are exposed to contamination from the environment. These can be e.g. fumes, cigarette smoke, or kitchen smells. If we do not wash these items regularly, these odors will linger on curtains, drapes and blinds, which can negatively affect the air quality in our homes.
            </p>
            <p>
            Thirdly, washing curtains, drapes and blinds can help maintain their original appearance. These window fittings are often exposed to sunlight, which can cause color fading and fabric damage. Regular washing can help maintain their colors and texture for longer.
            </p>
            <img className="window" src={window2} title='window' alt="window"/>
            <p>It is also worth noting that washing curtains, curtains and blinds is beneficial not only for our health, but also for our wallets. If we wash these elements regularly, they will last longer and we will not have to replace them with new ones as often.</p>
            <p>To sum up, washing curtains, curtains and roller blinds is an important activity that we should not overlook in keeping our homes clean. It helps to remove contaminants, improve air quality and maintain the original appearance of fabrics. Regular washing of these items is good for both our health and our wallets.</p>
            <p> </p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <FirstScreenEn />
        <Lang />
        <Furnitura />
        <FiveSCreenEn />
        </div>
    );
}
export default ArticelTwo;