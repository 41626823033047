// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Reviews.css';
import foto1 from '../img/HopClean/reviews/Maksym_Dovhenko.jpg';
import foto2 from '../img/HopClean/reviews/Justyna_Cofala.jpg';
import foto3 from '../img/HopClean/reviews/Marta Ozga.jpg';
import foto4 from '../img/HopClean/reviews/Oleksandr_Stanislavov.jpg';
import foto5 from '../img/HopClean/reviews/Vitalii_Holubiatykov.jpg';
import foto6 from '../img/HopClean/reviews/Kateryna.png';
import foto7 from '../img/HopClean/reviews/Anna_Wierbic.png';

// import required modules
import { EffectFlip, Pagination, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
function ReviewOne() {
  return (
    <div className="review">
      <img src={foto1} alt="Maksym_Dovhenko" className="review__img" />
      <p className="review__text">
        Jestem zadowolony z serwisu, szybko i punktualnie pracują, zawsze można
        się dogadać o cenach oraz przyjemnie mieć z nimi sprawę. Polecam.
      </p>

      <Link
        to="https://www.facebook.com/HOFCLEAN/reviews"
        target="_blank"
        alt="review-link"
        className="review__link"
      >
        <span>Szczegółowo...</span>
      </Link>
    </div>
  );
}
function Review2() {
  return (
    <div className="review">
      <img src={foto2} alt="Maksym_Dovhenko" className="review__img" />
      <p className="review__text">
        Polecam bardzo. Szybko, dokładnie, ludzie przemili 😊
      </p>

      <Link
        to="https://www.facebook.com/HOFCLEAN/reviews"
        target="_blank"
        alt="review-link"
        className="review__link"
      >
        <span>Szczegółowo...</span>
      </Link>
    </div>
  );
}
function Review3() {
  return (
    <div className="review">
      <img src={foto3} alt="Maksym_Dovhenko" className="review__img" />
      <p className="review__text">
        Błyskawicznie przyjęte zlecenie wyprania materaca, rano zadzwoniłam a
        popołudniu już był wyczyszczony ,Pani z którą załatwiałam sprawę była
        bardzo miła, polecam...
      </p>

      <Link
        to="https://www.facebook.com/HOFCLEAN/reviews"
        target="_blank"
        alt="review-link"
        className="review__link"
      >
        <span>Szczegółowo...</span>
      </Link>
    </div>
  );
}
function Review4() {
  return (
    <div className="review">
      <img src={foto4} alt="Maksym_Dovhenko" className="review__img" />
      <p className="review__text">
        Wysoko wykwalifikowani specjaliści, polecam.
      </p>

      <Link
        to="https://www.facebook.com/HOFCLEAN/reviews"
        target="_blank"
        alt="review-link"
        className="review__link"
      >
        <span>Szczegółowo...</span>
      </Link>
    </div>
  );
}
function Review5() {
  return (
    <div className="review">
      <img src={foto5} alt="Maksym_Dovhenko" className="review__img" />
      <p className="review__text">
        Bo to jest naprawdę szybko i jakościowo.Polecam!
      </p>

      <Link
        to="https://www.facebook.com/HOFCLEAN/reviews"
        target="_blank"
        alt="review-link"
        className="review__link"
      >
        <span>Szczegółowo...</span>
      </Link>
    </div>
  );
}
function Review6() {
  return (
    <div className="review">
      <img src={foto6} alt="Maksym_Dovhenko" className="review__img kateryna" />
      <p className="review__text">
        Polecam, szybkie i profesjonalne czyszczenie sofy na sucho. Bardzo
        polecam Pana Witka👍. Polecam, pełen profesjonalizm!
      </p>

      <Link
        to="https://maps.app.goo.gl/zLE2btcaZi2Tjzo89"
        target="_blank"
        alt="review-link"
        className="review__link kateryna-link"
      >
        <span>Szczegółowo...</span>
      </Link>
    </div>
  );
}
function Review7() {
  return (
    <div className="review">
      <img src={foto7} alt="Maksym_Dovhenko" className="review__img kateryna" />
      <p className="review__text">
        Usługa przeprowadzona sprawnie i starannie. Serdecznie polecam. Meble
        dawno nie były tak czyste. Od razu milej się korzysta. Zdjęcia kolejnego
        dnia po wyschnięciu.
      </p>

      <Link
        to="https://maps.app.goo.gl/1eCSBS6ZohiYt5HS8"
        target="_blank"
        alt="review-link"
        className="review__link kateryna-link"
      >
        <span>Szczegółowo...</span>
      </Link>
    </div>
  );
}

function SwiperReviews() {
  return (
    <>
      <Swiper
        loop={true}
        effect={'flip'}
        grabCursor={true}
        pagination={true}
        navigation={true}
        modules={[EffectFlip, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <ReviewOne />
        </SwiperSlide>
        <SwiperSlide>
          <Review2 />
        </SwiperSlide>
        <SwiperSlide>
          <Review3 />
        </SwiperSlide>
        <SwiperSlide>
          <Review4 />
        </SwiperSlide>
        <SwiperSlide>
          <Review5 />
        </SwiperSlide>
        <SwiperSlide>
          <Review6 />
        </SwiperSlide>
        <SwiperSlide>
          <Review7 />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
export default SwiperReviews;
