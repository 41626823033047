import React from 'react';
import ContainerOne from './ContainerOne';
import './FirstScreen.css';
import Lang from './Lang';
function FirstScreen() {
    return (
        <>
            <ContainerOne />
            <Lang />
        </>
    );
}

export default FirstScreen;
