import React, { useEffect, useRef, useState } from "react";
import './HeaderLogin.css';
import { Link } from 'react-router-dom';

function LoginWithBackDrop () {
    const [isHidden, setHidden] = useState(false);
    const [isHiddenLogin, setHiddenLogin] = useState(false);
    const getLogin = useRef();
    const getPassword = useRef();

    useEffect(() => {
      if (localStorage.getItem('user')) {
      onSignin(localStorage.getItem('user'));
      setHiddenLogin(true);
    }  
    }, []);

  function onSignin (login) {
    localStorage.setItem('user', login);
    setHiddenLogin(true);
    setHidden(false);
  }

  function onSignout () {
    setHiddenLogin(false);
    localStorage.removeItem('user');
  }

    function validatorUser(login, password) {
      switch(login) {
          case 'Admin':
              return password === '12345';
          case 'Metthew':
              return password === '132';
          default: 
          return;
      }
  }
    const submit = (e) => {
      e.preventDefault();
      const myLogin = getLogin.current.value;
      const myPassword = getPassword.current.value;

      if (validatorUser(myLogin, myPassword)){
        onSignin(myLogin);
      }else {
        alert('You login or password not validate');
      }
      getLogin.current.value = '';
      getPassword.current.value = '';
    }

    if(isHidden) {
        document.querySelector('body').style.overflow = 'hidden';
      }else {
        document.querySelector('body').style.overflow = 'visible';
  }
    return (
        <>
        <div className={isHiddenLogin? 'user' : 'user hidden'} id="user-info">
        <Link to='admin21'> <button id="user-name">{(localStorage.length > 0 ? localStorage.getItem('user') : 'no user')}</button></Link>
        <button onClick={onSignout} className={isHiddenLogin? "loginExit" : 'hidden' }id="sign-out-btn">Вихід</button>
        </div>
        <button onClick={() => setHidden(true)} className={isHiddenLogin?'hidden': 'hidden'} id="sign-in-btn" type="button">Zaloguj sie</button>
        
       {isHidden && (<div onClick={() => setHidden(false)} className={isHidden ? 'backdrop': 'backdrop hidden'} id="backdrop"> </div>) } 

        <form onSubmit={submit} id="form" className={isHidden ? "form" : 'form hidden'}>
        <input ref={getLogin} name="login" type="text" placeholder="login" required/> 
        <input ref={getPassword} name="password" type="password" placeholder="password" required/>
        <div className="btnCanelExit">
          <button  onClick={() => setHidden(false)} id="btnCancel" type="button" className="btn">Cancel</button>
          <button onClick={() => setHiddenLogin(false)} id="btnSignin" type="submit" className="btn">Sign in</button>
        </div>
        </form>
       
        </>
    );
}

export default LoginWithBackDrop;

