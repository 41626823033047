import React from 'react';
import './General_img.css';
import Foto9 from '../img/HopClean/articel.jpg';
import Furnitura3 from '../img/HopClean/articel2.jpg';
import { Link } from 'react-router-dom';

function Block () {
    return (
        <div className="block">
            <h3>Why do you need to wash the upholstery</h3>
        </div>
    );
}
function BlockOneImgP () {
    return (
        <div>
            <img src={Foto9} alt="foto9"/>
            <p className="special">Washing upholstered furniture is one of the most important activities related to maintaining hygiene in our homes or workplaces. Upholstered furniture is not only a place to relax, but also often serves as a surface to work or eat... </p>
        </div>
    );
}
function BlockOneCheck () {
    return (
        <div className="check">
            <Link to="/en/articelone">More details...</Link>
        </div>
    );
}
function BlockOneFromGeneral () {
    return (
        <div className="block_1">
            <Block />
            <BlockOneImgP />
            <BlockOneCheck />
        </div>
    );
}
function BlockTwo () {
    return (
        <div className="block">
             <h3>Laundry of mattresses Krakow</h3>
        </div>
    );
}
function BlockTwoImgP () {
    return (
        <div>
            <img src={Furnitura3} alt="foto9"/>
            <p className="special">The mattress is one of the most important elements of our bedroom, and at the same time the place where we spend about a third of our lives. Therefore, it is important that our mattress is clean and hygienic...</p>
        </div>
    );
}
function BlockTwoCheck () {
    return (
        <div className="check">
            <Link to="/en/articeltwo">More details...</Link>
        </div>
    );
}
function BlockTwoFromGeneral () {
    return (
        <div className="block_2">
            <BlockTwo />
            <BlockTwoImgP />
            <BlockTwoCheck />
        </div>
    );
}
function GeneralImg () {
    return (
        <div className="general_img">
             <BlockOneFromGeneral />
             <BlockTwoFromGeneral />
         </div>
    );
}

export default GeneralImg;