import React from 'react';
import './Gallery.css';
import Gallery_1 from '../img/HopClean/galler.jpg';
import Gallery_2 from '../img/HopClean/galler2.jpg';
import Gallery_3 from '../img/HopClean/galler3.jpg';
import Gallery_4 from '../img/HopClean/galler4.jpg';

function GalleryOne () {
    return (
        <figure className="galley_1">
            <figcaption>Mattress washing</figcaption>
            <img src={Gallery_1} alt="foto1"/>
        </figure>
    );
}
function GalleryTwo () {
    return (
        <figure className="galley_2">
            <figcaption>Washing carpets and rugs</figcaption>
            <img src={Gallery_2} alt="foto20"/>
    </figure>
    );
}
function GalleryThree () {
    return (
        <figure className="galley_3">
            <figcaption>Washing furniture upholstery</figcaption>
            <img src={Gallery_3} alt="foto13"/>
    </figure>
    );
}
function GalleryFour () {
    return (
        <figure className="show closed">
            <figcaption>Washing car upholstery</figcaption>
            <img src={Gallery_4} alt="foto3"/>
        </figure>
    );
}
function Gallery () {
    return (
        <div className="galley">
              <GalleryOne />
              <GalleryTwo />
              <GalleryThree />
              <GalleryFour />
         </div>
    );
}


export default Gallery;