import React, { useState } from 'react';
import './BurgerMenu.css';
import BackDropLogin from './HeaderLogin';
import { Link, NavLink } from 'react-router-dom';

export function FullMenu() {
  return (
    <>
      <ul id="fullMenu">
        <li>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? 'active-link' : '')}
          >
            Główny
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/o-nas"
            className={({ isActive }) => (isActive ? 'active-link' : '')}
          >
            O nas
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/cennik"
            className={({ isActive }) => (isActive ? 'active-link' : '')}
          >
            Cennik
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/galiria"
            className={({ isActive }) => (isActive ? 'active-link' : '')}
          >
            Galeria
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/kontakt"
            className={({ isActive }) => (isActive ? 'active-link' : '')}
          >
            Kontakt
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/articelone"
            className={({ isActive }) => (isActive ? 'active-link' : '')}
          >
            Blog
          </NavLink>
        </li>
      </ul>
    </>
  );
}
function NavWrap() {
  const [isHidden, setHidden] = useState(false);
  function onState() {
    if (isHidden) {
      setHidden(false);
    } else {
      setHidden(true);
    }
  }
  // console.log(isHidden);
  return (
    <>
      {isHidden && (
        <div
          onClick={() => setHidden(false)}
          className={isHidden ? 'back-dropShortMenu' : ''}
        >
          {' '}
        </div>
      )}
      <nav id="wrap" className={isHidden ? 'wrap open' : 'wrap'}>
        <div id="shortMenu" className="closed show">
          <button
            onClick={() => onState()}
            className="burger_menu_btn"
            id="burger_btn"
          >
            <span id="spanMenu"></span>
            <span id="spanMenu2"></span>
            <span id="spanMenu3"></span>
          </button>
          <ul id="menu" className="menu">
            <li>
              <Link to="/">Główny</Link>
            </li>
            <li>
              <Link to="/o-nas">O nas</Link>
            </li>
            <li>
              <Link to="/cennik">Cennik</Link>
            </li>
            <li>
              <Link to="/galiria">Galeria</Link>
            </li>
            <li>
              <Link to="/kontakt">Kontakt</Link>
            </li>
            <li>
              <Link to="/articelone">Blog</Link>
            </li>
          </ul>
        </div>
        <FullMenu />
      </nav>
    </>
  );
}
function NavMenu() {
  return (
    <div className="navBtn">
      <NavWrap />
      <BackDropLogin />
    </div>
  );
}

export default NavMenu;
