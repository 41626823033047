import FiveScreen from '../../FiveScreen/FiveScreen';
import window from './../../img/HopClean/article9.jpg';
import window2 from '../../img/HopClean/article3.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import FirstScreen from "../../FirstScreen/ContainerOne";
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';


function Lang () {
    const [isLang, setLang] = useState(false);
    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>Pl</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/en/articel9'>En</Link> :<Link to='/articel9'>Pl</Link>} </button>
        </div>
        </>
    )
}

function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Pranie zasłon, firan, rolet</h2>
            <p>
            Pranie zasłon, firan i rolet to często pomijana czynność w utrzymaniu czystości w naszych domach. Jednakże, jest to bardzo ważna czynność, której nie powinniśmy ignorować. Pranie tych elementów wyposażenia okien to nie tylko kwestia estetyki, ale przede wszystkim zdrowia. Poniżej przedstawiamy kilka powodów, dla których pranie zasłon, firan i rolet jest tak ważne.
            </p>
            <p>
            Po pierwsze, zasłony, firany i rolety gromadzą wiele kurzu, pyłków i innych zanieczyszczeń, które mogą powodować problemy z układem oddechowym. Wiele osób cierpi na alergie i astmę, a takie zanieczyszczenia mogą wpływać negatywnie na ich zdrowie. Regularne pranie tych elementów może pomóc w usunięciu zanieczyszczeń i poprawić jakość powietrza w naszych domach.
            </p>
            <img className="window" src={window} title='window' alt="window"/>
            <p>
            Po drugie, zasłony, firany i rolety są narażone na zanieczyszczenia z otoczenia. Mogą to być m.in. spaliny, dym papierosowy, czy też zapachy z kuchni. Jeśli nie będziemy regularnie prać tych elementów, to zapachy te będą utrzymywać się na zasłonach, firanach i roletach, co może wpłynąć negatywnie na jakość powietrza w naszych domach.
            </p>
            <p>
            Po trzecie, pranie zasłon, firan i rolet może pomóc w utrzymaniu ich pierwotnego wyglądu. Te elementy wyposażenia okien często są narażone na działanie promieni słonecznych, co może powodować blaknięcie kolorów i uszkodzenia tkanin. Regularne pranie może pomóc w utrzymaniu ich kolorów i faktury na dłużej.
            </p>
            <img className="window" src={window2} title='window' alt="window"/>
            <p>Warto również zwrócić uwagę, że pranie zasłon, firan i rolet jest korzystne nie tylko dla naszego zdrowia, ale także dla naszych portfeli. Jeśli będziemy regularnie prać te elementy, to będą one trwalsze i nie będziemy musieli tak często wymieniać ich na nowe.</p>
            <p>Podsumowując, pranie zasłon, firan i rolet to ważna czynność, której nie powinniśmy pomijać w utrzymaniu czystości w naszych domach. Pomaga ona w usuwaniu zanieczyszczeń, poprawie jakości powietrza oraz utrzymaniu pierwotnego wyglądu tkanin. Regularne pranie tych elementów jest korzystne zarówno dla naszego zdrowia, jak i dla naszych portfeli.</p>
            <p> </p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <FirstScreen />
        <Lang />
        <Furnitura />
        <FiveScreen />
        </div>
    );
}
export default ArticelTwo;