import React from "react";
import './FiveScreen.css';
import ContainerFive from "./ContainerFive";
import Footer from "./Footer";

function FiveScreen () {
    return (
        <div className="five_screen">
        <ContainerFive />
        <Footer />
        </div>
    );
}

export default FiveScreen;