import FiveScreen from '../../FiveScreen/FiveScreen';
import window from './../../img/HopClean/articel8.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from "./ArticelOne";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import FirstScreen from "../../FirstScreen/ContainerOne";
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';


function Lang () {
    const [isLang, setLang] = useState(false);
    const changeLang = () => {
        setLang(isLang => !isLang);
    }
   
    return (
        <>
        <div onClick={changeLang} className={isLang?"back-drop": 'hidden'}></div>
        <div className="lang">
          <button onClick={changeLang} className="select"><span>Pl</span> <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'}/> </button>
          <button className={isLang? 'en' : 'hidden'}>{isLang ? <Link to='/en/articel8'>En</Link> :<Link to='/articel8'>Pl</Link>} </button>
        </div>
        </>
    )
}

function Furnitura() {
    return (
        <>
<div className="containerarticel">
    <div className="atricelandcatalog">
        <div className="articelquilon" >
            <h2>Jak często należy czyścić dywan w biurze </h2>
            <p>
            Dywan jest popularnym wyborem dla biur ze względu na swoją funkcjonalność i estetykę. Jednak, aby zachować jego czystość i trwałość, należy regularnie go czyścić. W tym artykule omówimy, jak często należy czyścić dywan w biurze i dlaczego jest to ważne.
            </p>
            <p>
            Przede wszystkim, warto pamiętać, że dywan jest podatny na gromadzenie się kurzu, brudu, roztoczy i innych alergenów. Te substancje mogą wpływać na jakość powietrza w biurze, a także prowadzić do alergii i innych problemów zdrowotnych. Dlatego tak ważne jest regularne czyszczenie dywanu.
            </p>
            <img className="window" src={window} title='window' alt="window"/>
            <p>
            Ogólnie, zaleca się, aby dywan w biurze był odkurzany co najmniej raz na tydzień. Jednak, jeśli w biurze jest dużo ruchu, a dywan jest intensywnie używany, może to wymagać częstszego odkurzania. W takim przypadku zaleca się odkurzanie dywanu co najmniej trzy razy w tygodniu.
            </p>
            <p>
            Jeśli chodzi o głębsze czyszczenie, to zaleca się to przeprowadzać co najmniej raz na rok. W przypadku intensywnie zabrudzonego dywanu zaleca się skorzystanie z usług profesjonalnych firm czyszczących. Profesjonalne czyszczenie pozwoli na usunięcie trudniejszych do usunięcia zabrudzeń i odświeżenie dywanu.
            </p>
            <p>Istnieje wiele metod czyszczenia dywanu, w tym odkurzanie, pranie na sucho, pranie na mokro i czyszczenie parowe. Wybór odpowiedniej metody zależy od rodzaju dywanu, stopnia zabrudzenia oraz preferencji i budżetu biura. W przypadku głębszego czyszczenia, najlepiej skorzystać z usług profesjonalnych firm czyszczących, które posiadają odpowiednie narzędzia i środki czyszczące.</p>
            <p>Ważne jest również, aby regularnie usuwać plamy z dywanu. W przypadku większych plam zaleca się jak najszybsze ich usunięcie, aby zapobiec trudniejszym do usunięcia plamom i zabrudzeniom. Do usuwania plam można użyć specjalnych środków czyszczących lub domowych metod, takich jak wykorzystanie wody z mydłem lub roztworu octu.</p>
            <p>Podsumowując, dywan w biurze należy regularnie odkurzać, aby zachować jego czystość i trwałość, a także zapobiec gromadzeniu się kurzu, brudu i alergenów. Zaleca się odkurzanie dywanu co najmniej raz na tydzień, ale jeśli dywan jest intensywnie używany, może to wymagać częstszego odkurzania. </p>
            </div>
        <Cataloge />
    </div>
</div>
        </>
    );
}
function ArticelTwo () {
    return (
        <div className="body_wrapper">
        <FirstScreen />
        <Lang />
        <Furnitura />
        <FiveScreen />
        </div>
    );
}
export default ArticelTwo;