import React, { useState } from 'react';
import './BurgerMenu.css';
import BackDropLogin from './HeaderLogin';
import { Link, NavLink } from 'react-router-dom';

export function FullMenu() {
  return (
    <>
      <ul id="fullMenu">
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? 'active-link-eng' : '')}
            to="/en/"
          >
            Main
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? 'active-link-eng' : '')}
            to="/en/o-nas"
          >
            About Us
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? 'active-link-eng' : '')}
            to="/en/cennik"
          >
            Price
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? 'active-link-eng' : '')}
            to="/en/galiria"
          >
            Gallery
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? 'active-link-eng' : '')}
            to="/en/kontakt"
          >
            Contact
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? 'active-link-eng' : '')}
            to="/en/articelone"
          >
            Blog
          </NavLink>
        </li>
      </ul>
    </>
  );
}
function NavWrap() {
  const [isHidden, setHidden] = useState(false);
  function onState() {
    if (isHidden) {
      setHidden(false);
    } else {
      setHidden(true);
    }
  }
  // console.log(isHidden);
  return (
    <>
      {isHidden && (
        <div
          onClick={() => setHidden(false)}
          className={isHidden ? 'back-dropShortMenu' : ''}
        >
          {' '}
        </div>
      )}
      <nav id="wrap" className={isHidden ? 'wrap open' : 'wrap'}>
        <div id="shortMenu" className="closed show">
          <button
            onClick={() => onState()}
            className="burger_menu_btn"
            id="burger_btn"
          >
            <span id="spanMenu"></span>
            <span id="spanMenu2"></span>
            <span id="spanMenu3"></span>
          </button>
          <ul id="menu" className="menu">
            <li>
              <Link to="/en">Main</Link>
            </li>
            <li>
              <Link to="/en/o-nas">About Us</Link>
            </li>
            <li>
              <Link to="/en/cennik">Price</Link>
            </li>
            <li>
              <Link to="/en/galiria">Gallery</Link>
            </li>
            <li>
              <Link to="/en/kontakt">Contact</Link>
            </li>
            <li>
              <Link to="/en/articelone">Blog</Link>
            </li>
          </ul>
        </div>
        <FullMenu />
      </nav>
    </>
  );
}
function NavMenu() {
  return (
    <div className="navBtn">
      <NavWrap />
      <BackDropLogin />
    </div>
  );
}

export default NavMenu;
