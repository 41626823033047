import window from './../../../components/img/HopClean/updateArticles/foto2.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>En</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/articel23">Pl</Link>
          ) : (
            <Link to="/en/articel13">En</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>How to clean a baby stroller at home?</h2>
            <p>
              Cleaning a baby stroller is necessary to ensure comfort and
              hygienic conditions for your child during walks. Regularly keeping
              the stroller clean also extends its life. Below I present step by
              step how to clean a baby stroller at home.
            </p>
            <img className="window" src={window} title="window" alt="window" />
            <p>What will you need?</p>
            <ul className="decical-ol">
              <li>Hot water</li>
              <li>Gentle detergent (preferably unscented)</li>
              <li> Sponge or soft cloth</li>
              <li>Toothbrush (for hard-to-reach places)</li>
              <li>Handheld vacuum cleaner (optional)</li>
              <li>Paper or cotton towels</li>
              <li>Plastic bowl</li>
              <li>Metal/plastic cleaners (optional)</li>
              <li>Disinfectant (safe for children)</li>
            </ul>

            <p>
              Step 1: Preparation Before cleaning, inspect the stroller
              carefully. Check which parts are removable and whether there are
              any cleaning instructions from the manufacturer. Remove all
              movable elements, such as covers, pillows and seat belts.
            </p>
            <p>
              Step 2: Washing fabrics Removable fabrics (covers, inserts) should
              be washed according to the manufacturer's recommendations. Most
              fabrics can be machine washed on a delicate cycle using a mild
              detergent. After washing, leave them to air dry.
            </p>
            <p>
              Step 3: Cleaning the frame The frame of the stroller is most often
              made of metal or plastic. Prepare a solution of warm water with a
              mild detergent. Use a sponge or soft cloth to thoroughly clean the
              entire frame. Pay particular attention to places where dirt
              accumulates, such as joints and wheels. Use a toothbrush for
              hard-to-reach places.
            </p>
            <p>
              Step 4: Clean the wheels Wheels often collect the most dirt.
              Remove them if possible and clean them thoroughly using the same
              mixture of water and detergent. Check the mechanisms for dried mud
              or other debris. After cleaning, dry the wheels thoroughly.
            </p>
            <p>
              Step 5: Cleaning belts and small parts Seat belts and other small
              parts should be cleaned by hand. You can use a damp cloth with a
              little detergent. Wipe the belts and components thoroughly and dry
              them with a paper towel.
            </p>
            <p>
              Step 6: Disinfection After thoroughly washing all parts of the
              stroller, wipe them with a disinfectant. Make sure you use a
              child-safe product and allow all components to dry thoroughly
              before reassembling the stroller.
            </p>
            <p>
              Step 7: Assembling the stroller Once all parts are dry and clean,
              reassemble the stroller. Check that all components are securely
              attached and that the trolley works properly.
            </p>
            <p>
              Regularly cleaning your baby stroller not only improves its
              appearance, but also ensures a healthy and safe environment for
              your baby. It is worth performing these activities every few weeks
              to keep the stroller in perfect condition.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreenEn />
      <Lang />
      <Furnitura />
      <FiveSCreenEn />
    </div>
  );
}
export default ArticelTwo;
